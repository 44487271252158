import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { api } from "../../Services/Axios";
import { config } from "react-spring";
import Geocode from "react-geocode";
import { useConfig } from "../../context/ConfigContext";
import LoadingScreen from "../LoadingScreen";
import ErrorScreen from "../ErrorScreen";
import "./classes/ProgressRing";
import {
  EmpreendimentoContainer,
  EmpreendimentoContainerBackground,
  LogoGafisa,
  EmpreendimentoTitle,
  EmpreendimentoQr,
  EmpreendimentoAddress,
  EmpreendimentoAddressDetails,
  EmpreendimentoDescription,
  EmpreendimentoUnits,
  UnitsNumber,
  UnitsText,
  UnitsCircle,
  EmpreendimentoImagem,
  EmpreendimentoPorcentagem,
  EmpreendimentoPorcentagemBarra,
  EmpreendimentoPorcentagemBarra2,
  EmpreendimentosPlantas,
  EmpreendimentosPlantasDegrade,
  EmpreendimentosPlantasThumb,
  EmpreendimentosThumbs,
  EmpreendimentosThumbsThumb,
  EmpreendimentosThumbsThumbBig,
  EmpreendimentosMap,
  EmpreendimentosMapEmbed,
  EmpreendimentosMapPath,
  EmpreendimentosMapInterests,
  EmpreendimentosMapInterestsItem,
  EmpreendimentosMapInterestsGradient,
  EmpreendimentosMapCloseBt,
  CarouselContainerFachada,
  CarouselContainerAreaComum,
  CarouselContainerPerspectivas,
  CarouselContainerPlantas,
  CarouselCloseButton,
  CarouselZoomButton,
  PlantaInferiorButton,
  PlantaSuperiorButton,
  ImageContainerFachada,
  ImageContainerAreaComum,
  ImageContainerPerspectivas,
  ImageContainerPlantas,
} from "./styled";
import Maps from "./components/Maps";
import CarouselSliderFachada from "./components/CarouselSliderFachada";
import CarouselSliderAreaComum from "./components/CarouselSliderAreaComum";
import CarouselSliderPerspectivas from "./components/CarouselSliderPerspectivas";
import OpenedImageFachada from "./components/OpenedImageFachada";
import OpenedImageAreaComum from "./components/OpenedImageAreaComum";
import OpenedImagePerspectivas from "./components/OpenedImagePerspectivas";
import CarouselSliderPlantas from "./components/CarouselSliderPlantas";
import OpenedImagePlantas from "./components/OpenedImagePlantas";

const Empreendimento = () => {
  let { id } = useParams();
  const { configApp } = useConfig();

  Geocode.setApiKey("AIzaSyDQsRqcHq-NWTl3PLYfk_JR5hxXNX6kOVo");
  Geocode.setLanguage("en");
  Geocode.setRegion("br");
  Geocode.setLocationType("ROOFTOP");
  // Geocode.enableDebug();

  const [imagesListFachada, setImagesListFachada] = useState([]);
  const [imagesListAreaComum, setImagesListAreaComum] = useState([]);
  const [imagesListPerspectivas, setImagesListPerspectivas] = useState([]);

  const [imagesListPlantas, setImagesListPlantas] = useState([]);

  const [mapsInterestsList, setMapsInterestsList] = useState([
    {
      text: null,
      distance: null,
      atracao_id: null,
      empreendimento_id: null,
      location: null,
    },
  ]);

  const [isLoading, setIsLoading] = useState(true);

  const [errorScreen, setErrorScreen] = useState(false);

  const unidadesTotal = 150;

  const unidadesDisponiveis = 40;

  let porcentagemAtualTemp = 0;

  let porcentagemAtualTemp2 = 0;

  const porcentagemConcluida = Math.floor(Math.random() * 60) + 20;

  const porcentagemConcluida2 = Math.floor(Math.random() * 60) + 20;

  const [empreendimento, setEmpreendimento] = useState([]);

  const [unidadesAtuais, setUnidadesAtuais] = useState(unidadesTotal);

  let unidadesAtuaisTemp = unidadesTotal;

  const [porcentagemAtual, setPorcentagemAtual] = useState(0);

  const [porcentagemAtual2, setPorcentagemAtual2] = useState(0);

  const [empreendimentoClasses, setEmpreendimentoClasses] = useState({
    logoGafisa: "",
    empreendimentoTitle: "",
    empreendimentoAddress: "",
    empreendimentoAddressDetails: "",
    empreendimentoUnits: "",
    empreendimentoImagem: "",
    empreendimentoPorcentagem: "",
    empreendimentoDescription: "",
    empreendimentoPlantas: "",
    empreendimentoThumbs: "",
    empreendimentoMap: "",
    empreendimentoMapEmbed: "",
    empreendimentoMapList: "",
    empreendimentoMapPath: "",
    empreendimentoMapCloseBt: "",
  });

  const [classDuplexButtonInferior, setClassDuplexButtonInferior] =
    useState("active");
  const [classDuplexButtonSuperior, setClassDuplexButtonSuperior] =
    useState("");

  const [mapStyle, setMapStyle] = useState({
    width: "567.39px",
    height: "567.39px",
    borderRadius: "50%",
    transition: "all 1s ease-in-out 0s",
    pointerEvents: "none",
  });

  const [mapSetup, setMapSetup] = useState({
    centers: [
      {
        lat: 0,
        lng: 0,
      },
    ],
    originGeocode: [
      {
        lat: 0,
        lng: 0,
      },
    ],
  });

  const [originPoint, setOriginPoint] = useState("");

  const [direction, setDirection] = useState({
    response: null,
    travelMode: "DRIVING",
    origin: originPoint,
    destination: "",
    distance: null,
    empreendimento_id: null,
    atracao_id: null,
  });

  const [mapOpened, setMapOpened] = useState(false);

  const openedImageFachadaRef = useRef();
  const openedImageAreaComumRef = useRef();
  const openedImagePerspectivasRef = useRef();

  const openedImagePlantasRef = useRef();

  const [buildCarousel, setBuildCarousel] = useState(false);

  const [apiLoadedLength, setApiLoadedLength] = useState(0);
  const [slidersLoadedLength, setSlidersLoadedLength] = useState(0);

  const [setupCarouselFachada, setSetupCarouselFachada] = useState({
    class: "",
    slidesLength: 0,
    currentSlide: 0,
    goToSlide: 0,
    offsetRadius: 1,
    showNavigation: false,
    config: config.slow,
    imageOpened: "",
  });

  const [setupCarouselAreaComum, setSetupCarouselAreaComum] = useState({
    class: "",
    slidesLength: 0,
    currentSlide: 0,
    goToSlide: 0,
    offsetRadius: 1,
    showNavigation: false,
    config: config.slow,
    imageOpened: "",
  });

  const [setupCarouselPerspectivas, setSetupCarouselPerspectivas] = useState({
    class: "",
    slidesLength: 0,
    currentSlide: 0,
    goToSlide: 0,
    offsetRadius: 1,
    showNavigation: false,
    config: config.slow,
    imageOpened: "",
  });

  const [setupCarouselPlantas, setSetupCarouselPlantas] = useState({
    class: "",
    slidesLength: 0,
    currentSlide: 0,
    goToSlide: 0,
    offsetRadius: 1,
    showNavigation: false,
    config: config.slow,
    imageOpened: "",
    isDuplex: false,
    floor: "inferior",
  });

  const [imageClosedFachada, _setImageClosedFachada] = useState(false);
  const imageClosedFachadaRef = useRef(imageClosedFachada);

  const setImageClosedFachada = (data) => {
    imageClosedFachadaRef.current = data;
    _setImageClosedFachada(data);
  };

  const [imageClosedAreaComum, _setImageClosedAreaComum] = useState(false);
  const imageClosedAreaComumRef = useRef(imageClosedAreaComum);

  const setImageClosedAreaComum = (data) => {
    imageClosedAreaComumRef.current = data;
    _setImageClosedAreaComum(data);
  };

  const [imageClosedPerspectivas, _setImageClosedPerspectivas] =
    useState(false);
  const imageClosedPerspectivasRef = useRef(imageClosedPerspectivas);

  const setImageClosedPerspectivas = (data) => {
    imageClosedPerspectivasRef.current = data;
    _setImageClosedPerspectivas(data);
  };

  const [imageClosedPlantas, _setImageClosedPlantas] = useState(false);

  const imageClosedPlantasRef = useRef(imageClosedPlantas);

  const setImageClosedPlantas = (data) => {
    imageClosedPlantasRef.current = data;
    _setImageClosedPlantas(data);
  };

  const setCenters = (address) => {
    Geocode.fromAddress(address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setMapSetup((values) => ({
          ...values,
          centers: [
            {
              lat: lat,
              lng: lng,
            },
          ],
        }));
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const setOriginGeocode = (address) => {
    Geocode.fromAddress(address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setMapSetup((values) => ({
          ...values,
          centers: [
            {
              lat: lat,
              lng: lng,
            },
          ],
          originGeocode: [
            {
              lat: lat,
              lng: lng,
            },
          ],
        }));
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const listPlantasThumbs = imagesListPlantas.map((planta, index) => (
    <EmpreendimentosPlantasThumb
      key={index}
      style={{
        backgroundImage: `url(${planta.thumb})`,
      }}
      onClick={() => {
        openCarouselPlantas(index);
      }}
    >
      <div className="title">{planta.title}</div>
      <div className="text">{planta.text}</div>
    </EmpreendimentosPlantasThumb>
  ));

  const listMapsInterests = mapsInterestsList.map((mapItem, index) => (
    <EmpreendimentosMapInterestsItem
      key={index}
      className={empreendimentoClasses.empreendimentoMapList}
      onClick={() => {
        if (!mapOpened) {
          openMaps();
        }
        calculateDirection(
          mapSetup.originGeocode[0],
          mapItem.location,
          mapItem.distance,
          mapItem.empreendimento_id,
          mapItem.atracao_id
        );
      }}
    >
      <div className={`meters ${empreendimentoClasses.empreendimentoMapList}`}>
        •
      </div>
      <div className={`text ${empreendimentoClasses.empreendimentoMapList}`}>
        {mapItem.text}
      </div>
    </EmpreendimentosMapInterestsItem>
  ));

  //**************** CAROUSEL Fachada  ***************/

  const openCarouselFachada = (index) => {
    // console.log(index);
    setSetupCarouselFachada((values) => ({
      ...values,
      class: "in",
      currentSlide: index,
      goToSlide: index,
      imageOpened: "",
    }));
  };

  const closeCarouselFachada = () => {
    setSetupCarouselFachada((values) => ({
      ...values,
      class: "",
      imageOpened: "",
    }));
  };

  const openImageFachada = () => {
    setSetupCarouselFachada((values) => ({
      ...values,
      imageOpened: "in",
    }));
  };

  const closeImageFachada = () => {
    setSetupCarouselFachada((values) => ({
      ...values,
      class: "in",
      imageOpened: "",
    }));
    setImageClosedFachada(true);
    openedImageFachadaRef.current.setFromOutside({
      rotateX: 0,
      rotateY: 0,
      rotateZ: 0,
      scale: 1,
      zoom: 0,
      x: 0,
      y: 0,
    });
  };

  //**************** CAROUSEL Área Comum  ***************/

  const openCarouselAreaComum = (index) => {
    // console.log(index);
    setSetupCarouselAreaComum((values) => ({
      ...values,
      class: "in",
      currentSlide: index,
      goToSlide: index,
      imageOpened: "",
    }));
  };

  const closeCarouselAreaComum = () => {
    setSetupCarouselAreaComum((values) => ({
      ...values,
      class: "",
      imageOpened: "",
    }));
  };

  const openImageAreaComum = () => {
    setSetupCarouselAreaComum((values) => ({
      ...values,
      imageOpened: "in",
    }));
  };

  const closeImageAreaComum = () => {
    setSetupCarouselAreaComum((values) => ({
      ...values,
      class: "in",
      imageOpened: "",
    }));
    setImageClosedAreaComum(true);
    openedImageAreaComumRef.current.setFromOutside({
      rotateX: 0,
      rotateY: 0,
      rotateZ: 0,
      scale: 1,
      zoom: 0,
      x: 0,
      y: 0,
    });
  };

  //**************** CAROUSEL PERSPECTIVAS  ***************/

  const openCarouselPerspectivas = (index) => {
    // console.log(index);
    setSetupCarouselPerspectivas((values) => ({
      ...values,
      class: "in",
      currentSlide: index,
      goToSlide: index,
      imageOpened: "",
    }));
  };

  const closeCarouselPerspectivas = () => {
    setSetupCarouselPerspectivas((values) => ({
      ...values,
      class: "",
      imageOpened: "",
    }));
  };

  const openImagePerspectivas = () => {
    setSetupCarouselPerspectivas((values) => ({
      ...values,
      imageOpened: "in",
    }));
  };

  const closeImagePerspectivas = () => {
    setSetupCarouselPerspectivas((values) => ({
      ...values,
      class: "in",
      imageOpened: "",
    }));
    setImageClosedPerspectivas(true);
    openedImagePerspectivasRef.current.setFromOutside({
      rotateX: 0,
      rotateY: 0,
      rotateZ: 0,
      scale: 1,
      zoom: 0,
      x: 0,
      y: 0,
    });
  };

  //**************** CAROUSEL Plantas  ***************/

  const openCarouselPlantas = (index) => {
    // console.log(index);
    setSetupCarouselPlantas((values) => ({
      ...values,
      class: "",
      currentSlide: index,
      goToSlide: index,
      imageOpened: "",
      isDuplex: imagesListPlantas[index].url2 !== "" ? true : false,
      floor: "inferior",
    }));
    openImagePlantas();
  };

  const closeCarouselPlantas = () => {
    setSetupCarouselPlantas((values) => ({
      ...values,
      class: "",
      imageOpened: "",
    }));
  };

  const openImagePlantas = () => {
    setSetupCarouselPlantas((values) => ({
      ...values,
      imageOpened: "in",
    }));
  };

  const closeImagePlantas = () => {
    setSetupCarouselPlantas((values) => ({
      ...values,
      class: "in",
      imageOpened: "",
    }));
    setImageClosedPlantas(true);
    openedImagePlantasRef.current.setFromOutside({
      rotateX: 0,
      rotateY: 0,
      rotateZ: 0,
      scale: 1,
      zoom: 0,
      x: 0,
      y: 0,
    });
    closeCarouselPlantas();
    setClassDuplexButtonInferior("active");
    setClassDuplexButtonSuperior("");
  };

  const chooseDuplex = (value) => {
    setSetupCarouselPlantas((values) => ({
      ...values,
      floor: value,
    }));
    if (value === "inferior") {
      setClassDuplexButtonInferior("active");
      setClassDuplexButtonSuperior("");
    } else if (value === "superior") {
      setClassDuplexButtonInferior("");
      setClassDuplexButtonSuperior("active");
    }
  };

  const openMaps = () => {
    if (!mapOpened) {
      setMapOpened(true);
      setEmpreendimentoClasses((classes) => ({
        ...classes,
        logoGafisa: "",
        empreendimentoTitle: "inMap",
        empreendimentoAddress: "inMap",
        empreendimentoAddressDetails: "",
        empreendimentoUnits: "",
        empreendimentoImagem: "",
        empreendimentoPorcentagem: "",
        empreendimentoDescription: "",
        empreendimentoPlantas: "",
        empreendimentoThumbs: "",
        empreendimentoMap: "in",
        empreendimentoMapEmbed: "in",
        empreendimentoMapList: "active",
        empreendimentoMapPath: "off",
        empreendimentoMapCloseBt: "in",
      }));
      setMapStyle((values) => ({
        ...values,
        width: "50vw",
        height: "100vh",
        borderRadius: "0%",
        pointerEvents: "all",
      }));
    }
  };

  const closeMaps = () => {
    if (mapOpened) {
      setMapOpened(false);
      setEmpreendimentoClasses((classes) => ({
        ...classes,
        logoGafisa: "in",
        empreendimentoTitle: "in",
        empreendimentoAddress: "in",
        empreendimentoAddressDetails: "in",
        empreendimentoUnits: "in",
        empreendimentoImagem: "in",
        empreendimentoPorcentagem: "in",
        empreendimentoDescription: "in",
        empreendimentoPlantas: "in",
        empreendimentoThumbs: "in",
        empreendimentoMap: "in",
        empreendimentoMapEmbed: "",
        empreendimentoMapList: "",
        empreendimentoMapPath: "",
        empreendimentoMapCloseBt: "",
      }));
      setMapStyle((values) => ({
        ...values,
        width: "567.39px",
        height: "567.39px",
        borderRadius: "50%",
        pointerEvents: "none",
      }));
      setDirection((direction) => ({
        ...direction,
        response: null,
        origin: originPoint,
        destination: "",
        travelMode: "DRIVING",
        distance: null,
        empreendimento_id: null,
        atracao_id: null,
      }));
      setCenters(originPoint);
    }
  };

  const calculateDirection = (
    origin,
    destination,
    distance,
    empreendimento_id,
    atracao_id
  ) => {
    setDirection((direction) => ({
      ...direction,
      origin: origin,
      destination: destination,
      travelMode: "DRIVING",
      distance: distance,
      empreendimento_id: empreendimento_id,
      atracao_id: atracao_id,
    }));
  };

  useEffect(() => {
    api
      .get(`v1/empreendimentos/${id}`)
      .then(function (response) {
        if (response.data.success === true) {
          console.log("empreendimentos/id", response);
          let data = response.data.data;
          console.log(data);
          setEmpreendimento(data);

          let principalArr = response.data.data.files.principal.map(
            (value, index) => ({
              thumb: encodeURI(
                configApp.baseURLAssets +
                  response.data.data.files.thumb.principal[index]
              )
                .replace(/\(/g, "%28")
                .replace(/\)/g, "%29"),
              url: encodeURI(configApp.baseURLAssets + value)
                .replace(/\(/g, "%28")
                .replace(/\)/g, "%29"),
              title: "Principal",
            })
          );

          let fachadaArr = response.data.data.files.fachada.map(
            (value, index) => ({
              thumb: encodeURI(
                configApp.baseURLAssets +
                  response.data.data.files.thumb.fachada[index]
              )
                .replace(/\(/g, "%28")
                .replace(/\)/g, "%29"),
              url: encodeURI(configApp.baseURLAssets + value)
                .replace(/\(/g, "%28")
                .replace(/\)/g, "%29"),
              title: "Fachada",
            })
          );

          let areaComumArr = response.data.data.files["area-comum"].map(
            (value, index) => ({
              thumb: encodeURI(
                configApp.baseURLAssets +
                  response.data.data.files.thumb["area-comum"][index]
              )
                .replace(/\(/g, "%28")
                .replace(/\)/g, "%29"),
              url: encodeURI(configApp.baseURLAssets + value)
                .replace(/\(/g, "%28")
                .replace(/\)/g, "%29"),
              title: "Áreas Comuns",
            })
          );

          let perspectivasArr = response.data.data.files.perspectivas.map(
            (value, index) => ({
              thumb: encodeURI(configApp.baseURLAssets + value)
                .replace(/\(/g, "%28")
                .replace(/\)/g, "%29"),
              url: encodeURI(configApp.baseURLAssets + value)
                .replace(/\(/g, "%28")
                .replace(/\)/g, "%29"),
              title: "Perspectivas",
            })
          );

          setImagesListFachada((values) => [
            ...values,
            ...principalArr,
            ...fachadaArr,
          ]);

          setImagesListAreaComum((values) => [...values, ...areaComumArr]);

          setImagesListPerspectivas((values) => [
            ...values,
            ...perspectivasArr,
          ]);
          setApiLoadedLength((value) => value + 1);
        } else {
          // console.log(response);
          setIsLoading(false);
          setErrorScreen(true);
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
        setErrorScreen(true);
      });

    api
      .get(`v1/empreendimentos/${id}/plantas`)
      .then(function (response) {
        console.log("empreendimentos/id/plantas", response);
        if (response.data.success === true) {
          let plantasArr = response.data.data.map((value) => [
            {
              thumb: encodeURI(
                // configApp.baseURLAssets +
                value.image
              )
                .replace(/\(/g, "%28")
                .replace(/\)/g, "%29"),
              url: encodeURI(configApp.baseURLAssets + value.files[0])
                .replace(/\(/g, "%28")
                .replace(/\)/g, "%29"),
              url2: encodeURI(
                value.files[1] !== undefined
                  ? configApp.baseURLAssets + value.files[1]
                  : ""
              )
                .replace(/\(/g, "%28")
                .replace(/\)/g, "%29"),
              title: "Planta",
              text: value.name,
            },
          ]);
          // console.log("plantasArr", plantasArr);
          let tempArr = [];
          plantasArr.map((valor) => {
            tempArr = [...tempArr, ...valor];
          });
          setImagesListPlantas(tempArr);
          setApiLoadedLength((value) => value + 1);
        } else {
          // console.log(response);
          setIsLoading(false);
          setErrorScreen(true);
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
        setErrorScreen(true);
      });
  }, []);

  useEffect(() => {
    if (empreendimento.attractions !== undefined) {
      setOriginPoint(empreendimento.address);
      let arrayLocalidades = empreendimento.attractions;
      const tempMaps = arrayLocalidades.map((value) => {
        return {
          text: value.name,
          distance: value.pivot.distance,
          atracao_id: value.pivot.atracao_id,
          empreendimento_id: value.pivot.empreendimento_id,
          location: value.address,
        };
      });
      tempMaps.sort(function (a, b) {
        if (a.distance !== null && b.distance !== null) {
          return (
            Number(a.distance.substring(0, a.distance.length - 2)) -
            Number(b.distance.substring(0, b.distance.length - 2))
          );
        } else if (a.distance === null) {
          return 1;
        } else if (b.distance === null) {
          return -1;
        }
      });
      // console.log(tempMaps);
      setMapsInterestsList([...tempMaps]);
      setApiLoadedLength((value) => value + 1);
    }
  }, [empreendimento]);

  useEffect(() => {
    // console.log("originpoint", originPoint);
  }, [originPoint]);

  const apiLoaded = () => {
    setIsLoading(false);
    setErrorScreen(false);
    setOriginGeocode(originPoint);
    setEmpreendimentoClasses((classes) => ({
      ...classes,
      logoGafisa: "in",
      empreendimentoTitle: "in",
      empreendimentoAddress: "in",
      empreendimentoAddressDetails: "in",
      empreendimentoUnits: "in",
      empreendimentoImagem: "in",
      empreendimentoPorcentagem: "in",
      empreendimentoDescription: "in",
      empreendimentoPlantas: "in",
      empreendimentoThumbs: "in",
      empreendimentoMap: "in",
      empreendimentoMapEmbed: "",
      empreendimentoMapList: "",
      empreendimentoMapPath: "",
      empreendimentoMapCloseBt: "",
    }));

    let progress = 100 - (unidadesDisponiveis * 100) / unidadesTotal;
    const el = document.querySelector("progress-ring");
    const barra = document.querySelector("#empreendimento-porcentagem");
    const barra2 = document.querySelector("#empreendimento-porcentagem2");

    setTimeout(() => {
      el.setAttribute("progress", progress);
      barra.style.height = `${porcentagemConcluida}%`;
      barra2.style.height = `${porcentagemConcluida2}%`;
      let intervalUnidadesDisponiveis = setInterval(() => {
        if (unidadesAtuaisTemp >= unidadesDisponiveis) {
          let num = unidadesAtuaisTemp--;
          setUnidadesAtuais(num);
        } else {
          clearInterval(intervalUnidadesDisponiveis);
        }
      }, 10);

      let intervalPorcentagemAtual = setInterval(() => {
        if (porcentagemAtualTemp <= porcentagemConcluida) {
          let num2 = porcentagemAtualTemp++;
          setPorcentagemAtual(num2);
        } else {
          clearInterval(intervalPorcentagemAtual);
        }
      }, 20);

      let intervalPorcentagemAtual2 = setInterval(() => {
        if (porcentagemAtualTemp2 <= porcentagemConcluida2) {
          let num3 = porcentagemAtualTemp2++;
          setPorcentagemAtual2(num3);
        } else {
          clearInterval(intervalPorcentagemAtual2);
        }
      }, 20);
    }, 1500);
  };

  useEffect(() => {
    setSetupCarouselFachada((values) => ({
      ...values,
      slidesLength: imagesListFachada.length,
    }));
    setSlidersLoadedLength((value) => value + 1);
  }, [imagesListFachada]);

  useEffect(() => {
    setSetupCarouselAreaComum((values) => ({
      ...values,
      slidesLength: imagesListAreaComum.length,
    }));
    setSlidersLoadedLength((value) => value + 1);
  }, [imagesListAreaComum]);

  useEffect(() => {
    setSetupCarouselPerspectivas((values) => ({
      ...values,
      slidesLength: imagesListPerspectivas.length,
    }));
    setSlidersLoadedLength((value) => value + 1);
  }, [imagesListPerspectivas]);

  useEffect(() => {
    setSetupCarouselPlantas((values) => ({
      ...values,
      slidesLength: imagesListPlantas.length,
    }));
    setSlidersLoadedLength((value) => value + 1);
  }, [imagesListPlantas]);

  useEffect(() => {
    // console.log("api loaded", apiLoadedLength, slidersLoadedLength);
    if (slidersLoadedLength === 8 && apiLoadedLength === 2) {
      setTimeout(() => {
        apiLoaded();
        setBuildCarousel(true);
        // console.log("api loaded");
      }, 2000);
    }
  }, [apiLoadedLength]);

  useEffect(() => {
    // console.log("sliders loaded", apiLoadedLength, slidersLoadedLength);

    if (slidersLoadedLength === 8 && apiLoadedLength === 3) {
      setTimeout(() => {
        apiLoaded();
        setBuildCarousel(true);
        // console.log("api loaded");
      }, 2000);
    }
  }, [slidersLoadedLength]);

  return (
    <>
      {isLoading ? <LoadingScreen /> : null}
      {errorScreen ? <ErrorScreen /> : null}
      <EmpreendimentoContainer>
        <EmpreendimentoContainerBackground />
        <Link to="/mesagafisa">
          <LogoGafisa className={empreendimentoClasses.logoGafisa} />
        </Link>
        <EmpreendimentoTitle
          className={empreendimentoClasses.empreendimentoTitle}
        >
          <div className="text">{empreendimento.name}</div>
          <div className="sub">{empreendimento.district}</div>
        </EmpreendimentoTitle>
        <EmpreendimentoQr
          style={{
            backgroundImage: `url(${empreendimento["qr-code"]})`,
          }}
          className={empreendimentoClasses.empreendimentoAddress}
        />
        <EmpreendimentoAddress
          className={empreendimentoClasses.empreendimentoAddress}
        >
          {empreendimento.address}
          <br />
          <EmpreendimentoAddressDetails
            className={empreendimentoClasses.empreendimentoAddressDetails}
          >
            {empreendimento.high_description}
          </EmpreendimentoAddressDetails>
        </EmpreendimentoAddress>
        <EmpreendimentoUnits
          className={empreendimentoClasses.empreendimentoUnits}
        >
          {/* <UnitsNumber>{unidadesAtuais}</UnitsNumber>
        <UnitsText>unidades disponíveis</UnitsText> */}
          <UnitsCircle>
            <progress-ring stroke="4" radius="60" progress="0"></progress-ring>
          </UnitsCircle>
        </EmpreendimentoUnits>
        {imagesListFachada[0] !== undefined && (
          <EmpreendimentoImagem
            style={{
              backgroundImage: `url(${imagesListFachada[0].url})`,
            }}
            className={empreendimentoClasses.empreendimentoImagem}
            onClick={() => {
              openCarouselFachada(0);
            }}
          ></EmpreendimentoImagem>
        )}
        <EmpreendimentoPorcentagem
          className={empreendimentoClasses.empreendimentoPorcentagem}
        >
          <EmpreendimentoPorcentagemBarra>
            <div id="empreendimento-porcentagem"></div>
          </EmpreendimentoPorcentagemBarra>
          <div className="number">{porcentagemAtual}</div>
          <EmpreendimentoPorcentagemBarra2>
            <div id="empreendimento-porcentagem2"></div>
          </EmpreendimentoPorcentagemBarra2>
          <div className="number2">{porcentagemAtual2}</div>
          {/* <div className="text">concluído</div> */}
        </EmpreendimentoPorcentagem>
        <EmpreendimentoDescription
          className={empreendimentoClasses.empreendimentoDescription}
        >
          {empreendimento.description}
        </EmpreendimentoDescription>
        <EmpreendimentosPlantasDegrade
          className={empreendimentoClasses.empreendimentoPlantas}
        />
        <EmpreendimentosPlantas
          className={empreendimentoClasses.empreendimentoPlantas}
        >
          {listPlantasThumbs}
        </EmpreendimentosPlantas>
        <EmpreendimentosThumbs
          className={empreendimentoClasses.empreendimentoThumbs}
        >
          {imagesListAreaComum[0] !== undefined && (
            <EmpreendimentosThumbsThumb
              style={{
                backgroundImage: `linear-gradient(
            269.85deg,
            #141414 3.73%,
            rgba(20, 20, 20, 0) 86.62%
          ),url(${imagesListAreaComum[0].thumb})`,
              }}
              onClick={() => {
                openCarouselAreaComum(0);
              }}
            >
              <div className="text">{imagesListAreaComum[0].title}</div>
            </EmpreendimentosThumbsThumb>
          )}
          {imagesListPerspectivas[0] !== undefined && (
            <EmpreendimentosThumbsThumbBig
              style={{
                backgroundImage: `url(${imagesListPerspectivas[0].thumb})`,
              }}
              onClick={() => {
                openCarouselPerspectivas(0);
              }}
            >
              <div className="text">{imagesListPerspectivas[0].title}</div>
            </EmpreendimentosThumbsThumbBig>
          )}
        </EmpreendimentosThumbs>
        <EmpreendimentosMap className={empreendimentoClasses.empreendimentoMap}>
          <EmpreendimentosMapEmbed
            className={empreendimentoClasses.empreendimentoMapEmbed}
            onClick={() => {
              openMaps();
            }}
          >
            <Maps
              mapStyle={mapStyle}
              mapSetup={mapSetup}
              setMapSetup={setMapSetup}
              direction={direction}
              setDirection={setDirection}
            />
          </EmpreendimentosMapEmbed>
          <EmpreendimentosMapPath
            className={empreendimentoClasses.empreendimentoMapPath}
          />
          <EmpreendimentosMapInterests
            className={empreendimentoClasses.empreendimentoMapList}
          >
            {listMapsInterests}
          </EmpreendimentosMapInterests>
        </EmpreendimentosMap>
        <EmpreendimentosMapInterestsGradient
          className={empreendimentoClasses.empreendimentoMapList}
        />
        <EmpreendimentosMapCloseBt
          onClick={closeMaps}
          className={empreendimentoClasses.empreendimentoMapCloseBt}
        />

        {buildCarousel && (
          <>
            <CarouselContainerFachada className={setupCarouselFachada.class}>
              <CarouselSliderFachada
                setupCarouselFachada={setupCarouselFachada}
                setSetupCarouselFachada={setSetupCarouselFachada}
                imagesListFachada={imagesListFachada}
              />
              <CarouselCloseButton onClick={closeCarouselFachada} />
              <CarouselZoomButton onClick={openImageFachada} />
            </CarouselContainerFachada>
            <CarouselContainerAreaComum
              className={setupCarouselAreaComum.class}
            >
              <CarouselSliderAreaComum
                setupCarouselAreaComum={setupCarouselAreaComum}
                setSetupCarouselAreaComum={setSetupCarouselAreaComum}
                imagesListAreaComum={imagesListAreaComum}
              />
              <CarouselCloseButton onClick={closeCarouselAreaComum} />
              <CarouselZoomButton onClick={openImageAreaComum} />
            </CarouselContainerAreaComum>
            <CarouselContainerPerspectivas
              className={setupCarouselPerspectivas.class}
            >
              <CarouselSliderPerspectivas
                setupCarouselPerspectivas={setupCarouselPerspectivas}
                setSetupCarouselPerspectivas={setSetupCarouselPerspectivas}
                imagesListPerspectivas={imagesListPerspectivas}
              />
              <CarouselCloseButton onClick={closeCarouselPerspectivas} />
              <CarouselZoomButton onClick={openImagePerspectivas} />
            </CarouselContainerPerspectivas>
            <CarouselContainerPlantas className={setupCarouselPlantas.class}>
              <CarouselSliderPlantas
                setupCarouselPlantas={setupCarouselPlantas}
                setSetupCarouselPlantas={setSetupCarouselPlantas}
                imagesListPlantas={imagesListPlantas}
              />
              <CarouselCloseButton onClick={closeCarouselPlantas} />
              <CarouselZoomButton onClick={openImagePlantas} />
            </CarouselContainerPlantas>
          </>
        )}
        <ImageContainerFachada className={setupCarouselFachada.imageOpened}>
          <OpenedImageFachada
            ref={openedImageFachadaRef}
            setupCarouselFachada={setupCarouselFachada}
            imagesListFachada={imagesListFachada}
            imageClosedFachada={imageClosedFachada}
            setImageClosedFachada={setImageClosedFachada}
          />
          <CarouselCloseButton onClick={closeImageFachada} />
        </ImageContainerFachada>
        <ImageContainerAreaComum className={setupCarouselAreaComum.imageOpened}>
          <OpenedImageAreaComum
            ref={openedImageAreaComumRef}
            setupCarouselAreaComum={setupCarouselAreaComum}
            imagesListAreaComum={imagesListAreaComum}
            imageClosedAreaComum={imageClosedAreaComum}
            setImageClosedAreaComum={setImageClosedAreaComum}
          />
          <CarouselCloseButton onClick={closeImageAreaComum} />
        </ImageContainerAreaComum>
        <ImageContainerPerspectivas
          className={setupCarouselPerspectivas.imageOpened}
        >
          <OpenedImagePerspectivas
            ref={openedImagePerspectivasRef}
            setupCarouselPerspectivas={setupCarouselPerspectivas}
            imagesListPerspectivas={imagesListPerspectivas}
            imageClosedPerspectivas={imageClosedPerspectivas}
            setImageClosedPerspectivas={setImageClosedPerspectivas}
          />
          <CarouselCloseButton onClick={closeImagePerspectivas} />
        </ImageContainerPerspectivas>
        <ImageContainerPlantas className={setupCarouselPlantas.imageOpened}>
          <OpenedImagePlantas
            ref={openedImagePlantasRef}
            setupCarouselPlantas={setupCarouselPlantas}
            imagesListPlantas={imagesListPlantas}
            imageClosedPlantas={imageClosedPlantas}
            setImageClosedPlantas={setImageClosedPlantas}
          />
          <CarouselCloseButton onClick={closeImagePlantas} />
          {setupCarouselPlantas.isDuplex && (
            <>
              <PlantaInferiorButton
                className={classDuplexButtonInferior}
                onClick={() => chooseDuplex("inferior")}
              >
                Inferior
              </PlantaInferiorButton>
              <PlantaSuperiorButton
                className={classDuplexButtonSuperior}
                onClick={() => chooseDuplex("superior")}
              >
                Superior
              </PlantaSuperiorButton>
            </>
          )}
        </ImageContainerPlantas>
      </EmpreendimentoContainer>
    </>
  );
};

export default Empreendimento;

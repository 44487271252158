import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
body {
    margin: 0;
    padding: 0;
    // background-color:#141414;
    overflow:hidden;
}
`;

export default GlobalStyle;

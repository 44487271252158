import styled, { keyframes } from "styled-components";
import linhasVerticais from "../images/linhas-verticais.png";
import linhaPontilhada from "../images/linha-pontilhada.png";
import pizzaSPBg from "../images/pizza-SP-bg.png";
import pizzaRJBg from "../images/pizza-RJ-bg.png";
import aroSP from "../images/aro-sp.png";
import aroRJ from "../images/aro-rj.png";
import btEmpreendimentos from "../images/bt-empreendimentos.png";
import btEmpreendimentosOver from "../images/bt-empreendimentos-over.png";

export const lightAnimation = keyframes`
 0% { opacity:0; }
 50% { opacity:1; }
 100% { opacity:0; }
`;

export const HomeContainer = styled.div`
  position: absolute;
  display: flex;
  width: 100vw;
  height: 100vh;
  left: 0px;
  top: 0px;
  background-image: linear-gradient(
      108.57deg,
      rgba(148, 158, 255, 0.55) -0.28%,
      rgba(148, 158, 255, 0) 32.03%,
      rgba(255, 26, 81, 0) 89.43%,
      rgba(255, 26, 81, 0.23) 110.8%,
      rgba(255, 26, 81, 0.23) 110.8%
    ),
    url(${linhasVerticais});
  background-color: #141414;
  background-size: cover;
`;

export const HomeContainerBackground = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0px;
  top: 0px;
  background-image: linear-gradient(
      108.57deg,
      rgba(148, 158, 255, 0.55) -10.28%,
      rgba(148, 158, 255, 0) 9%,
      rgba(255, 26, 81, 0) 67.48%,
      rgba(255, 26, 81, 0.23) 99.8%,
      rgba(255, 26, 81, 0.23) 99.8%
    ),
    url(${linhasVerticais});
  background-color: #141414;
  background-size: cover;
  animation-name: ${lightAnimation};
  animation-duration: 10s;
  animation-iteration-count: infinite;
`;

export const HomeTitle = styled.div`
  pointer-events: none;
  user-select: none;
  position: absolute;
  width: 800px;
  height: 200px;
  left: 168px;
  top: 105px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 120px;
  line-height: 100px;
  color: #ffffff;
  opacity: 0;
  transition: all 1s ease-in-out 0.2s;

  &.on {
    opacity: 1;
  }

  &.off {
    opacity: 1;
    left: 168px;
    top: -315px;
  }
`;

export const PizzaSP = styled.div`
  position: absolute;
  width: 727px;
  height: 727px;
  left: 278px;
  top: 456px;
  background-image: url(${pizzaSPBg});
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0;
  transform: scale(0.5) rotateZ(-20deg);
  transition: all 1s ease-in-out 0.4s;

  &.on {
    transform: scale(1) rotateZ(0deg);
    opacity: 1;
  }

  &.in {
    transform: scale(1) rotateZ(0deg);
    left: -116px;
    top: calc(50vh - 364px);
    opacity: 1;
  }

  &.half {
    width: 597px;
    height: 597px;
    left: -96px;
    top: 233px;
    opacity: 0.4;
    transform: scale(1) rotateZ(0deg);
  }

  &.off {
    width: 597px;
    height: 597px;
    left: -600px;
    top: 233px;
    opacity: 0.4;
    transition: all 0.7s ease-in-out;
  }

  div.circle {
    position: absolute;
    width: 605px;
    height: 605px;
    left: 61px;
    top: 61px;
    border: 2px solid #ffffff;
    border-radius: 305px;
    box-sizing: border-box;
    transition: all 1s ease-in-out;

    &.in {
      width: 897px;
      height: 897px;
      left: -83px;
      top: -83px;
      border-radius: 450px;
    }

    &.half {
      width: 505px;
      height: 505px;
      left: 41px;
      top: 51px;
    }

    div.circleGlow {
      position: absolute;
      width: 605px;
      height: 605px;
      border: 2px solid #ffffff;
      border-radius: 305px;
      box-sizing: border-box;
      filter: blur(16px);
      transition: all 1s ease-in-out;

      &.in {
        width: 897px;
        height: 897px;
        border-radius: 450px;
      }

      &.half {
        width: 505px;
        height: 505px;
        left: 0px;
        top: 0px;
      }
    }
  }
`;

export const PizzaRJ = styled.div`
  position: absolute;
  width: 735px;
  height: 735px;
  left: 1079px;
  top: -59px;
  background-image: url(${pizzaRJBg});
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0;
  transform: scale(0.5) rotateZ(-20deg);
  transition: all 1s ease-in-out 0.6s;

  &.on {
    transform: scale(1) rotateZ(0deg);
    opacity: 1;
  }
  &.in {
    transform: scale(1) rotateZ(0deg);
    left: 1280px;
    top: calc(50vh - 364px);
    opacity: 1;
  }

  &.half {
    width: 597px;
    height: 597px;
    left: 1415px;
    top: 233px;
    opacity: 0.4;
    transform: scale(1) rotateZ(0deg);
  }

  &.off {
    width: 597px;
    height: 597px;
    left: 1920px;
    top: 233px;
    opacity: 0.4;
    transition: all 0.7s ease-in-out;
  }

  div.circle {
    position: absolute;
    width: 605px;
    height: 605px;
    left: 61px;
    top: 61px;
    border: 2px solid #ffffff;
    border-radius: 305px;
    box-sizing: border-box;
    transition: all 1s ease-in-out;

    &.in {
      width: 897px;
      height: 897px;
      left: -83px;
      top: -83px;
      border-radius: 450px;
    }

    &.half {
      width: 505px;
      height: 505px;
      left: 51px;
      top: 51px;
    }

    div.circleGlow {
      position: absolute;
      width: 605px;
      height: 605px;
      border: 2px solid #ffffff;
      border-radius: 305px;
      box-sizing: border-box;
      filter: blur(16px);
      transition: all 1s ease-in-out;

      &.in {
        width: 897px;
        height: 897px;
        border-radius: 450px;
      }

      &.half {
        width: 505px;
        height: 505px;
        left: 0px;
        top: 0px;
      }
    }
  }
`;

export const PizzaTitleUF = styled.div`
  pointer-events: none;
  user-select: none;
  position: absolute;
  width: 237px;
  height: 260px;
  left: 249px;
  top: 237px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 200px;
  line-height: 260px;
  text-align: center;
  color: #ffffff;
  transition: all 1s ease-in-out;

  &.half {
    left: 185px;
    top: 157px;
  }
`;

export const HomeDescriptionSP = styled.div`
  pointer-events: none;
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: absolute;
  width: 272px;
  height: 108px;
  left: 349px;
  top: 402px;
  opacity: 0;
  transition: all 1s ease-in-out 1s;

  &.on {
    opacity: 1;
  }

  &.off {
    opacity: 0;
    transition: all 1s ease-in-out 0s;
  }
`;

export const HomeDescriptionRJ = styled.div`
  pointer-events: none;
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  position: absolute;
  width: 163px;
  height: 108px;
  left: 1571px;
  top: 612px;
  opacity: 0;
  transition: all 1s ease-in-out 1s;

  &.on {
    opacity: 1;
  }

  &.off {
    opacity: 0;
    transition: all 1s ease-in-out 0s;
  }
`;

export const HomeDescriptionTitle = styled.div`
  position: static;
  width: 100%;
  height: 21px;
  left: 0px;
  top: 0px;

  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;

  color: #ffffff;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 8px 0px;
`;

export const HomeDescriptionText = styled.div`
  position: static;
  width: 100%;
  height: 21px;
  left: 0px;
  top: 29px;

  font-family: Space Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;

  text-transform: uppercase;

  color: #c6c6c6;

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 8px 0px;
`;

export const DashedLine = styled.div`
  pointer-events: none;
  user-select: none;
  position: absolute;
  width: 529px;
  height: 393px;
  left: 780px;
  top: 369px;
  background-image: url(${linhaPontilhada});
  opacity: 1;
  transform: scale(0);
  transition: all 0.7s ease-in-out 1.1s;

  &.on {
    transform: scale(1);
    opacity: 1;
  }

  &.off {
    transform: scale(0);
    opacity: 0;
    transition: all 1s ease-in-out 0s;
  }
`;

export const AroSP = styled.div`
  position: absolute;
  width: 1239px;
  height: 1080px;
  left: 0;
  top: 0;
  background-image: url(${aroSP});
  opacity: 0;
  transition: all 1s ease-in-out 1s;

  &.in {
    opacity: 1;
  }
`;

export const AroRJ = styled.div`
  position: absolute;
  width: 1239px;
  height: 1080px;
  right: 0;
  top: 0;
  background-image: url(${aroRJ});
  opacity: 0;
  transition: all 1s ease-in-out 1s;
  &.in {
    opacity: 1;
  }
`;

export const ButtonLancamentoSP = styled.div`
  pointer-events: none;
  user-select: none;
  position: absolute;
  width: 281px;
  height: 281px;
  left: 210px;
  top: 463px;
  text-align: center;
  opacity: 0;
  background-image: url(${btEmpreendimentos});
  background-size: cover;
  transition: all 1s ease-in-out 0.1s;
  &.in {
    pointer-events: all;
    width: 281px;
    height: 281px;
    opacity: 1;
    left: 789px;
    top: 120px;
  }
  &.active {
    pointer-events: all;
    width: 301px;
    height: 301px;
    opacity: 1;
    left: 789px;
    top: 120px;
    background-image: url(${btEmpreendimentosOver});
    transition: all 0.25s ease-in-out 0s;
  }
  &.deactive {
    pointer-events: all;
    width: 261px;
    height: 261px;
    opacity: 1;
    left: 789px;
    top: 120px;
    transition: all 0.25s ease-in-out 0s;
  }
  .number {
    position: absolute;
    width: 123px;
    height: 148px;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-65%);
    font-family: Space Mono;
    font-style: normal;
    font-weight: normal;
    font-size: 100px;
    line-height: 148px;
    text-align: center;
    color: #ffffff;
    opacity: 0.4;
  }
  .text {
    position: absolute;
    width: 164px;
    height: auto;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-family: DM Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
    color: #ffffff;
    text-transform: uppercase;
  }
`;

export const ButtonEmObrasSP = styled.div`
  pointer-events: none;
  user-select: none;
  position: absolute;
  width: 281px;
  height: 281px;
  left: 210px;
  top: 463px;
  text-align: center;
  opacity: 0;
  background-image: url(${btEmpreendimentos});
  background-size: cover;
  transition: all 1s ease-in-out 0.1s;
  &.in {
    pointer-events: all;
    width: 281px;
    height: 281px;
    opacity: 1;
    left: 1011px;
    top: 457px;
  }
  &.active {
    pointer-events: all;
    width: 301px;
    height: 301px;
    opacity: 1;
    left: 1011px;
    top: 457px;
    background-image: url(${btEmpreendimentosOver});
    transition: all 0.25s ease-in-out 0s;
  }
  &.deactive {
    pointer-events: all;
    width: 261px;
    height: 261px;
    opacity: 1;
    left: 1011px;
    top: 457px;
    transition: all 0.25s ease-in-out 0s;
  }
  .number {
    position: absolute;
    width: 123px;
    height: 148px;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-65%);
    font-family: Space Mono;
    font-style: normal;
    font-weight: normal;
    font-size: 100px;
    line-height: 148px;
    text-align: center;
    color: #ffffff;
    opacity: 0.4;
  }
  .text {
    position: absolute;
    width: 164px;
    height: auto;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-family: DM Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
    color: #ffffff;
    text-transform: uppercase;
  }
`;

export const ButtonProntoParaMorarSP = styled.div`
  pointer-events: none;
  user-select: none;
  position: absolute;
  width: 281px;
  height: 281px;
  left: 210px;
  top: 463px;
  text-align: center;
  opacity: 0;
  background-image: url(${btEmpreendimentos});
  background-size: cover;
  transition: all 1s ease-in-out 0.1s;
  &.in {
    pointer-events: all;
    width: 281px;
    height: 281px;
    opacity: 1;
    left: 710px;
    top: 727px;
  }
  &.active {
    pointer-events: all;
    width: 301px;
    height: 301px;
    opacity: 1;
    left: 710px;
    top: 727px;
    background-image: url(${btEmpreendimentosOver});
    transition: all 0.25s ease-in-out 0s;
  }
  &.deactive {
    pointer-events: all;
    width: 261px;
    height: 261px;
    opacity: 1;
    left: 710px;
    top: 727px;
    transition: all 0.25s ease-in-out 0s;
  }
  .number {
    position: absolute;
    width: 123px;
    height: 148px;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-65%);
    font-family: Space Mono;
    font-style: normal;
    font-weight: normal;
    font-size: 100px;
    line-height: 148px;
    text-align: center;
    color: #ffffff;
    opacity: 0.4;
  }
  .text {
    position: absolute;
    width: 164px;
    height: auto;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-family: DM Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
    color: #ffffff;
    text-transform: uppercase;
  }
`;

export const ButtonLancamentoRJ = styled.div`
  pointer-events: none;
  user-select: none;
  position: absolute;
  width: 281px;
  height: 281px;
  right: 110px;
  top: 283px;
  text-align: center;
  opacity: 0;
  background-image: url(${btEmpreendimentos});
  background-size: cover;
  transition: all 1s ease-in-out 0.1s;
  &.in {
    pointer-events: all;
    width: 281px;
    height: 281px;
    opacity: 1;
    right: 789px;
    top: 120px;
  }
  &.active {
    pointer-events: all;
    width: 301px;
    height: 301px;
    opacity: 1;
    right: 789px;
    top: 120px;
    background-image: url(${btEmpreendimentosOver});
    transition: all 0.25s ease-in-out 0s;
  }
  &.deactive {
    pointer-events: all;
    width: 261px;
    height: 261px;
    opacity: 1;
    right: 789px;
    top: 120px;
    transition: all 0.25s ease-in-out 0s;
  }
  .number {
    position: absolute;
    width: 123px;
    height: 148px;
    right: 50%;
    top: 50%;
    transform: translateX(50%) translateY(-65%);
    font-family: Space Mono;
    font-style: normal;
    font-weight: normal;
    font-size: 100px;
    line-height: 148px;
    text-align: center;
    color: #ffffff;
    opacity: 0.4;
  }
  .text {
    position: absolute;
    width: 164px;
    height: auto;
    right: 50%;
    top: 50%;
    transform: translateX(50%) translateY(-50%);
    font-family: DM Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
    color: #ffffff;
    text-transform: uppercase;
  }
`;

export const ButtonEmObrasRJ = styled.div`
  pointer-events: none;
  user-select: none;
  position: absolute;
  width: 281px;
  height: 281px;
  right: 110px;
  top: 283px;
  text-align: center;
  opacity: 0;
  background-image: url(${btEmpreendimentos});
  background-size: cover;
  transition: all 1s ease-in-out 0.1s;
  &.in {
    pointer-events: all;
    width: 281px;
    height: 281px;
    opacity: 1;
    right: 1011px;
    top: 457px;
  }
  &.active {
    pointer-events: all;
    width: 301px;
    height: 301px;
    opacity: 1;
    right: 1011px;
    top: 457px;
    background-image: url(${btEmpreendimentosOver});
    transition: all 0.25s ease-in-out 0s;
  }
  &.deactive {
    pointer-events: all;
    width: 261px;
    height: 261px;
    opacity: 1;
    right: 1011px;
    top: 457px;
    transition: all 0.25s ease-in-out 0s;
  }
  .number {
    position: absolute;
    width: 123px;
    height: 148px;
    right: 50%;
    top: 50%;
    transform: translateX(50%) translateY(-65%);
    font-family: Space Mono;
    font-style: normal;
    font-weight: normal;
    font-size: 100px;
    line-height: 148px;
    text-align: center;
    color: #ffffff;
    opacity: 0.4;
  }
  .text {
    position: absolute;
    width: 164px;
    height: auto;
    right: 50%;
    top: 50%;
    transform: translateX(50%) translateY(-50%);
    font-family: DM Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
    color: #ffffff;
    text-transform: uppercase;
  }
`;

export const ButtonProntoParaMorarRJ = styled.div`
  pointer-events: none;
  user-select: none;
  position: absolute;
  width: 281px;
  height: 281px;
  right: 110px;
  top: 283px;
  text-align: center;
  opacity: 0;
  background-image: url(${btEmpreendimentos});
  background-size: cover;
  transition: all 1s ease-in-out 0.1s;

  &.in {
    pointer-events: all;
    width: 281px;
    height: 281px;
    opacity: 1;
    right: 710px;
    top: 727px;
  }
  &.active {
    pointer-events: all;
    width: 301px;
    height: 301px;
    opacity: 1;
    right: 710px;
    top: 727px;
    background-image: url(${btEmpreendimentosOver});
    transition: all 0.25s ease-in-out 0s;
  }
  &.deactive {
    pointer-events: all;
    width: 261px;
    height: 261px;
    opacity: 1;
    right: 710px;
    top: 727px;
    transition: all 0.25s ease-in-out 0s;
  }
  .number {
    position: absolute;
    width: 123px;
    height: 148px;
    right: 50%;
    top: 50%;
    transform: translateX(50%) translateY(-65%);
    font-family: Space Mono;
    font-style: normal;
    font-weight: normal;
    font-size: 100px;
    line-height: 148px;
    text-align: center;
    color: #ffffff;
    opacity: 0.4;
  }
  .text {
    position: absolute;
    width: 164px;
    height: auto;
    right: 50%;
    top: 50%;
    transform: translateX(50%) translateY(-50%);
    font-family: DM Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
    color: #ffffff;
    text-transform: uppercase;
  }
`;

export const ListLancamentoSP = styled.ul`
  flex: 1 1 auto;
  // background-color: pink;
  overflow-y: auto;
  padding-top: 50px;
  flex-flow: column wrap;
  justify-content: center;
  align-items: left;
  align-content: center;
  text-align: left;
  list-style-type: none;
  position: absolute;
  width: 550px;
  min-height: 550vh;
  max-height: 500vh;
  right: 0;
  top: 100%;
  transform: translateY(0%);
  transition: top 1s ease-in-out, height 1s ease-in-out,
    max-height 1s ease-in-out, min-height 3s ease-in-out,
    transform 1s ease-in-out;

  &::-webkit-scrollbar {
    display: none;
  }

  &.in {
    top: 50%;
    transform: translateY(-50%);
    min-height: 0px;
    max-height: 1080px;
    height: auto;
    transition: top 1.2s ease-in-out, height 1.2s ease-in-out,
      max-height 1.15s ease-in-out, min-height 0.1s ease-in-out,
      transform 1.2s ease-in-out;
  }

  li {
    justify-content: center;
    align-items: left;
    align-content: center;
    user-select: none;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 35px;
    line-height: 250px;
    color: #ffffff;
    transition: all 1s ease-in-out;

    a {
      text-decoration: none;
      color: inherit;
    }

    &.in {
      line-height: 83px;
      &:last-child {
        height: 183px;
      }
      transition: all 1.5s ease-in-out;
    }

    .bullet {
      float: left;
      font-family: Space Mono;
      font-style: normal;
      font-weight: normal;
      font-size: 32px;
      margin-right: 20px;
      color: rgba(255, 255, 255, 0.3);
    }
  }
`;

export const ListEmObrasSP = styled.ul`
  flex: 1 1 auto;
  // background-color: pink;
  overflow-y: auto;
  padding-top: 50px;
  flex-flow: column wrap;
  justify-content: center;
  align-items: left;
  align-content: center;
  text-align: left;
  list-style-type: none;
  position: absolute;
  width: 550px;
  min-height: 550vh;
  max-height: 500vh;
  right: 0;
  top: 100%;
  transform: translateY(0%);
  transition: top 1s ease-in-out, height 1s ease-in-out,
    max-height 1s ease-in-out, min-height 3s ease-in-out,
    transform 1s ease-in-out;

  &::-webkit-scrollbar {
    display: none;
  }

  &.in {
    top: 50%;
    transform: translateY(-50%);
    min-height: 0px;
    max-height: 1080px;
    height: auto;
    transition: top 1.2s ease-in-out, height 1.2s ease-in-out,
      max-height 1.15s ease-in-out, min-height 0.1s ease-in-out,
      transform 1.2s ease-in-out;
  }

  li {
    justify-content: center;
    align-items: left;
    align-content: center;
    user-select: none;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 35px;
    line-height: 250px;
    color: #ffffff;
    transition: all 1s ease-in-out;

    a {
      text-decoration: none;
      color: inherit;
    }

    &.in {
      line-height: 83px;
      &:last-child {
        height: 183px;
      }
      transition: all 1.5s ease-in-out;
    }

    .bullet {
      float: left;
      font-family: Space Mono;
      font-style: normal;
      font-weight: normal;
      font-size: 32px;
      margin-right: 20px;
      color: rgba(255, 255, 255, 0.3);
    }
  }
`;

export const ListProntoParaMorarSP = styled.ul`
  flex: 1 1 auto;
  // background-color: pink;
  overflow-y: auto;
  padding-top: 50px;
  flex-flow: column wrap;
  justify-content: center;
  align-items: left;
  align-content: center;
  text-align: left;
  list-style-type: none;
  position: absolute;
  width: 550px;
  min-height: 550vh;
  max-height: 500vh;
  right: 0;
  top: 100%;
  transform: translateY(0%);
  transition: top 1s ease-in-out, height 1s ease-in-out,
    max-height 1s ease-in-out, min-height 3s ease-in-out,
    transform 1s ease-in-out;

  &::-webkit-scrollbar {
    display: none;
  }

  &.in {
    top: 50%;
    transform: translateY(-50%);
    min-height: 0px;
    max-height: 1080px;
    height: auto;
    transition: top 1.2s ease-in-out, height 1.2s ease-in-out,
      max-height 1.15s ease-in-out, min-height 0.1s ease-in-out,
      transform 1.2s ease-in-out;
  }

  li {
    justify-content: center;
    align-items: left;
    align-content: center;
    user-select: none;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 35px;
    line-height: 250px;
    color: #ffffff;
    transition: all 1s ease-in-out;

    a {
      text-decoration: none;
      color: inherit;
    }

    &.in {
      line-height: 83px;
      &:last-child {
        height: 183px;
      }
      transition: all 1.5s ease-in-out;
    }

    .bullet {
      float: left;
      font-family: Space Mono;
      font-style: normal;
      font-weight: normal;
      font-size: 32px;
      margin-right: 20px;
      color: rgba(255, 255, 255, 0.3);
    }
  }
`;

export const ListLancamentoRJ = styled.ul`
  flex: 1 1 auto;
  // background-color: pink;
  overflow-y: auto;
  padding-top: 50px;
  flex-flow: column wrap;
  justify-content: center;
  align-items: left;
  align-content: center;
  text-align: left;
  list-style-type: none;
  position: absolute;
  width: 550px;
  min-height: 550vh;
  max-height: 500vh;
  left: 0;
  top: 100%;
  transform: translateY(0%);
  transition: top 1s ease-in-out, height 1s ease-in-out,
    max-height 1s ease-in-out, min-height 3s ease-in-out,
    transform 1s ease-in-out;

  &::-webkit-scrollbar {
    display: none;
  }

  &.in {
    top: 50%;
    transform: translateY(-50%);
    min-height: 0px;
    max-height: 1080px;
    height: auto;
    transition: top 1.2s ease-in-out, height 1.2s ease-in-out,
      max-height 1.15s ease-in-out, min-height 0.1s ease-in-out,
      transform 1.2s ease-in-out;
  }

  li {
    justify-content: center;
    align-items: left;
    align-content: center;
    user-select: none;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 35px;
    line-height: 250px;
    color: #ffffff;
    transition: all 1s ease-in-out;

    a {
      text-decoration: none;
      color: inherit;
    }

    &.in {
      line-height: 83px;
      &:last-child {
        height: 183px;
      }
      transition: all 1.5s ease-in-out;
    }

    .bullet {
      float: left;
      font-family: Space Mono;
      font-style: normal;
      font-weight: normal;
      font-size: 32px;
      margin-right: 20px;
      color: rgba(255, 255, 255, 0.3);
    }
  }
`;

export const ListEmObrasRJ = styled.ul`
  flex: 1 1 auto;
  // background-color: pink;
  overflow-y: auto;
  padding-top: 50px;
  flex-flow: column wrap;
  justify-content: center;
  align-items: left;
  align-content: center;
  text-align: left;
  list-style-type: none;
  position: absolute;
  width: 550px;
  min-height: 550vh;
  max-height: 500vh;
  left: 0;
  top: 100%;
  transform: translateY(0%);
  transition: top 1s ease-in-out, height 1s ease-in-out,
    max-height 1s ease-in-out, min-height 3s ease-in-out,
    transform 1s ease-in-out;

  &::-webkit-scrollbar {
    display: none;
  }

  &.in {
    top: 50%;
    transform: translateY(-50%);
    min-height: 0px;
    max-height: 1080px;
    height: auto;
    transition: top 1.2s ease-in-out, height 1.2s ease-in-out,
      max-height 1.15s ease-in-out, min-height 0.1s ease-in-out,
      transform 1.2s ease-in-out;
  }

  li {
    justify-content: center;
    align-items: left;
    align-content: center;
    user-select: none;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 35px;
    line-height: 250px;
    color: #ffffff;
    transition: all 1s ease-in-out;

    a {
      text-decoration: none;
      color: inherit;
    }

    &.in {
      line-height: 83px;
      &:last-child {
        height: 183px;
      }
      transition: all 1.5s ease-in-out;
    }

    .bullet {
      float: left;
      font-family: Space Mono;
      font-style: normal;
      font-weight: normal;
      font-size: 32px;
      margin-right: 20px;
      color: rgba(255, 255, 255, 0.3);
    }
  }
`;

export const ListProntoParaMorarRJ = styled.ul`
  flex: 1 1 auto;
  // background-color: pink;
  overflow-y: auto;
  padding-top: 50px;
  flex-flow: column wrap;
  justify-content: center;
  align-items: left;
  align-content: center;
  text-align: left;
  list-style-type: none;
  position: absolute;
  width: 550px;
  min-height: 550vh;
  max-height: 500vh;
  left: 0;
  top: 100%;
  transform: translateY(0%);
  transition: top 1s ease-in-out, height 1s ease-in-out,
    max-height 1s ease-in-out, min-height 3s ease-in-out,
    transform 1s ease-in-out;

  &::-webkit-scrollbar {
    display: none;
  }

  &.in {
    top: 50%;
    transform: translateY(-50%);
    min-height: 0px;
    max-height: 1080px;
    height: auto;
    transition: top 1.2s ease-in-out, height 1.2s ease-in-out,
      max-height 1.15s ease-in-out, min-height 0.1s ease-in-out,
      transform 1.2s ease-in-out;
  }

  li {
    justify-content: center;
    align-items: left;
    align-content: center;
    user-select: none;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 35px;
    line-height: 250px;
    color: #ffffff;
    transition: all 1s ease-in-out;

    a {
      text-decoration: none;
      color: inherit;
    }

    &.in {
      line-height: 83px;
      &:last-child {
        height: 183px;
      }
      transition: all 1.5s ease-in-out;
    }

    .bullet {
      float: left;
      font-family: Space Mono;
      font-style: normal;
      font-weight: normal;
      font-size: 32px;
      margin-right: 20px;
      color: rgba(255, 255, 255, 0.3);
    }
  }
`;

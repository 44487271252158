import React, { useEffect, useContext, useState, createContext } from "react";

const configModel = {
  baseURL: "https://gafisa.alicewonders.ws/",
  baseURLAssets: "https://gafisa.alicewonders.ws/api/storage/",
  baseURLAPI: "https://gafisa.alicewonders.ws/api",
};

const ConfigContext = createContext();

export default function ConfigProvider({ children }) {
  const [configApp, setConfigApp] = useState(configModel);

  // useEffect(() => {
  //   console.log(config);
  // }, [config]);

  return (
    <ConfigContext.Provider value={{ configApp, setConfigApp }}>
      {children}
    </ConfigContext.Provider>
  );
}

export function useConfig() {
  const context = useContext(ConfigContext);
  if (!context)
    throw new Error("useConfig must be used within a ConfigProvider");

  const { configApp, setConfigApp } = context;

  return { configApp, setConfigApp };
}

export function getConfig() {
  return configModel;
}

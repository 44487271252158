import React, { useState, useEffect, useRef } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  DirectionsService,
  DirectionsRenderer,
} from "@react-google-maps/api";
import { api } from "../../../../Services/Axios";
import {
  DirectionsInfos,
  DirectionsInfosDistance,
  DirectionsInfosTime,
} from "./styled";
import marker from "./marker.png";
import markerDestination from "./marker-destination.png";

const Maps = ({ mapStyle, mapSetup, setMapSetup, direction, setDirection }) => {
  const [classInfos, setClassInfos] = useState("");
  const [currentZoom, setCurrentZoom] = useState(17);
  const [directionsInfos, setDirectionsInfos] = useState({
    distance: "0m",
    time: "0min",
  });

  const directionsCallback = (response) => {
    if (response !== null) {
      if (response.status === "OK") {
        let distance =
          (response.routes[0].legs[0].distance.value / 1000).toFixed(2) + "km";
        // if (direction.distance === null) {
        //   // console.log("gravando distance", distance);
        //   api
        //     .post(`v1/set-distance`, {
        //       empreendimento: direction.empreendimento_id,
        //       atracao: direction.atracao_id,
        //       distancia: distance,
        //     })
        //     .then(function (response) {
        //       // console.log(response);
        //     })
        //     .catch(function (error) {
        //       console.log(error);
        //     });
        // }
        let time =
          Math.round(response.routes[0].legs[0].duration.value / 60) + "min";
        setDirectionsInfos((values) => ({
          distance: distance,
          time: time,
        }));
        setDirection((values) => ({
          response,
        }));
        setClassInfos("in");
      } else {
        console.log("Maps response: ", response);
        setClassInfos("");
      }
    } else {
      setClassInfos("");
    }
  };

  useEffect(() => {
    console.log(direction);
    if (direction.response === null) {
      setClassInfos("");
    }
  }, [direction]);

  return (
    <>
      <GoogleMap
        mapContainerStyle={mapStyle}
        center={mapSetup.centers[0]}
        zoom={currentZoom}
        options={{ mapId: "eab5e52a3ea8b099" }}
      >
        {direction.response === null && (
          <Marker
            icon={{
              url: marker,
              scaledSize: new window.google.maps.Size(32 / 1.5, 42 / 1.5),
            }}
            position={mapSetup.centers[0]}
            // title="NM15"
          />
        )}
        {direction.destination !== "" && direction.origin !== "" && (
          <DirectionsService
            options={{
              destination: direction.destination,
              origin: direction.origin,
              travelMode: direction.travelMode,
              drivingOptions: {
                departureTime: new Date(Date.now()),
                trafficModel: "optimistic",
              },
            }}
            callback={directionsCallback}
            // onLoad={directionsService => { console.log('DirectionsService onLoad directionsService: ', directionsService) }}
            // onUnmount={directionsService => { console.log('DirectionsService onUnmount directionsService: ', directionsService) }}
          />
        )}

        {direction.response !== null && (
          <>
            <DirectionsRenderer
              directions={direction.response}
              options={{
                polylineOptions: {
                  stokeColor: "#000000",
                  strokeOpacity: 1,
                  strokeWeight: 2,
                },
                markerOptions: {
                  icon: {
                    url: marker,
                    scaledSize: new window.google.maps.Size(0, 0),
                  },
                },
              }}
              // onLoad={(directionsRenderer) => { console.log( "DirectionsRenderer onLoad directionsRenderer: ", directionsRenderer); }}
              // onUnmount={(directionsRenderer) => { console.log( "DirectionsRenderer onUnmount directionsRenderer: ", directionsRenderer ); }}
            />
            <Marker
              icon={{
                url: marker,
                scaledSize: new window.google.maps.Size(32 / 1.5, 42 / 1.5),
              }}
              position={{
                lat: direction.response.routes[0].legs[0].start_location.lat(),
                lng: direction.response.routes[0].legs[0].start_location.lng(),
              }}
              title="NM15"
            />
            <Marker
              icon={{
                url: markerDestination,
                scaledSize: new window.google.maps.Size(60 / 1.2, 41 / 1.2),
              }}
              position={{
                lat: direction.response.routes[0].legs[0].end_location.lat(),
                lng: direction.response.routes[0].legs[0].end_location.lng(),
              }}
              title="NM15"
            />
          </>
        )}
      </GoogleMap>
      <DirectionsInfos className={classInfos}>
        <DirectionsInfosDistance>
          {directionsInfos.distance}
        </DirectionsInfosDistance>
        <DirectionsInfosTime>{directionsInfos.time}</DirectionsInfosTime>
      </DirectionsInfos>
    </>
  );
};

export default React.memo(Maps);

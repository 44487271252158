customElements.define(
  "progress-ring",
  class extends HTMLElement {
    constructor() {
      super();

      setTimeout(() => {
        const stroke = this.getAttribute("stroke");
        const radius = this.getAttribute("radius");
        console.log(radius);
        const normalizedRadius = radius - stroke * 2;
        this._circumference = normalizedRadius * 2 * Math.PI;

        this._root = this.attachShadow({ mode: "open" });
        this._root.innerHTML = `
        <svg
            height="${radius * 2}"
            width="${radius * 2}"
           >
           <defs>
    <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
      <stop offset="0%" stop-color="rgba(66, 66, 66, 0)" />
      <stop offset="100%" stop-color="#424242" />
    </linearGradient>
  </defs>
           <circle
                stroke="url(#gradient)"
                stroke-width="${stroke}"
                fill="transparent"
                r="${normalizedRadius}"
               cx="${radius}"
               cy="${radius}"
              />
          </svg>
          <svg
            height="${radius * 2}"
            width="${radius * 2}"
           >
             <circle
               id="blur"
               stroke="#FE015D"
               stroke-dasharray="${this._circumference} ${this._circumference}"
               style="stroke-dashoffset:${this._circumference}"
               stroke-width="${stroke}"
               fill="transparent"
               r="${normalizedRadius}"
               cx="${radius}"
               cy="${radius}"
            />
          </svg>
          <svg
            height="${radius * 2}"
            width="${radius * 2}"
           >
            <circle
               id="progress"
               stroke="#FE015D"
               stroke-dasharray="${this._circumference} ${this._circumference}"
               style="stroke-dashoffset:${this._circumference}"
               stroke-width="${stroke}"
               fill="transparent"
               r="${normalizedRadius}"
               cx="${radius}"
               cy="${radius}"
            />
          </svg>
    
          <style>
          svg{
            position:absolute;
            transform:rotateY(180deg);
          }
          circle#blur {
            transition: stroke-dashoffset 1s;
            transform: rotate(-90deg);
            transform-origin: 50% 50%;
            box-sizing: border-box;
            filter: blur(3px);
          }
          circle#progress{
            transition: stroke-dashoffset 1s;
            transform: rotate(-90deg);
            transform-origin: 50% 50%;
            box-sizing: border-box;
          }
          </style>
        `;
      }, 100);
    }

    setProgress(percent) {
      const offset =
        this._circumference - (percent / 100) * this._circumference;
      try {
        const circleBlur = this._root.querySelector("#blur");
        const circle = this._root.querySelector("#progress");
        circleBlur.style.strokeDashoffset = offset;
        circle.style.strokeDashoffset = offset;
      } catch (err) {}
    }

    static get observedAttributes() {
      return ["progress"];
    }

    attributeChangedCallback(name, oldValue, newValue) {
      if (name === "progress") {
        this.setProgress(newValue);
      }
    }
  }
);

import styled from "styled-components";

export const ContainerPlanta = styled.div`
  position: absolute;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
`;

export const ContainerPlantaTitulo = styled.div`
  pointer-events: none;
  position: absolute;
  z-index: 10000;
  width: 609px;
  height: 83px;
  left: 222px;
  top: 49px;

  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 64px;
  line-height: 83px;
  text-align: center;

  color: #000000;
`;

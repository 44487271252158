import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import { useSpring, animated, to } from "react-spring";
import { useGesture } from "react-use-gesture";
import { ContainerPlanta, ContainerPlantaTitulo } from "./styled";

const OpenedImagePlantas = (
  {
    setupCarouselPlantas,
    imagesListPlantas,
    imageClosedPlantas,
    setImageClosedPlantas,
  },
  ref
) => {
  const [floorImage, setFloorImage] = useState("");
  const calcX = (y, ly) => -(y - ly - window.innerHeight / 2) / 20;
  const calcY = (x, lx) => (x - lx - window.innerWidth / 2) / 20;

  const domTarget = React.useRef(null);
  const [{ x, y, rotateX, rotateY, rotateZ, zoom, scale }, set] = useSpring(
    () => ({
      rotateX: 0,
      rotateY: 0,
      rotateZ: 0,
      scale: 1,
      zoom: 0,
      x: 0,
      y: 0,
      config: { mass: 5, tension: 350, friction: 40 },
    })
  );

  const [lastOffset, setLastOffset] = useState([0, 0]);

  const [drag, setDrag] = React.useState(false);

  useEffect(() => {
    if (imagesListPlantas[setupCarouselPlantas.currentSlide] !== undefined) {
      setFloorImage(
        setupCarouselPlantas.floor === "inferior"
          ? imagesListPlantas[setupCarouselPlantas.currentSlide].url
          : imagesListPlantas[setupCarouselPlantas.currentSlide].url2
      );
    }
  }, [setupCarouselPlantas]);

  useImperativeHandle(
    ref,
    () => ({
      setFromOutside(msg) {
        set(msg);
      },
    }),
    []
  );

  useGesture(
    {
      onDragStart: ({ offset }) => {
        if (imageClosedPlantas) {
          if (lastOffset[0] !== offset[0] && lastOffset[1] !== offset[1]) {
            setImageClosedPlantas(false);
          }
        }
      },
      onDrag: ({ offset }) => {
        if (!imageClosedPlantas) {
          setDrag(true);
          set({ x: offset[0], y: offset[1], rotateX: 0, rotateY: 0, scale: 1 });
        }
      },
      onDragEnd: ({ offset }) => {
        setDrag(false);
        if (!imageClosedPlantas) {
          setLastOffset(offset);
        } else {
          offset[0] = 0;
          offset[1] = 0;
        }
      },
      onPinch: ({ offset: [d, a] }) => set({ zoom: d / 1000 }),
      // onMove: ({ xy: [px, py], dragging }) =>
      //   !dragging &&
      //   set({
      //     rotateX: calcX(py, y.get()),
      //     rotateY: calcY(px, x.get()),
      //     scale: 1.1,
      //   }),
      onHover: ({ hovering }) =>
        !hovering && set({ rotateX: 0, rotateY: 0, scale: 1 }),
    },

    { domTarget, eventOptions: { passive: false } }
  );

  return (
    <ContainerPlanta>
      <ContainerPlantaTitulo>
        {/* {imagesListPlantas[setupCarouselPlantas.currentSlide] !== undefined
          ? imagesListPlantas[setupCarouselPlantas.currentSlide].text
          : ""} */}
      </ContainerPlantaTitulo>
      <animated.div
        ref={domTarget}
        className={`${drag ? "dragging" : ""}`}
        style={{
          width: "100vw",
          height: "100vh",
          backgroundImage: `url(${
            imagesListPlantas[setupCarouselPlantas.currentSlide] !== undefined
              ? floorImage
              : ""
          })`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          transform: "perspective(600px)",
          x,
          y,
          scale: to([scale, zoom], (s, z) => s + z),
        }}
      ></animated.div>
    </ContainerPlanta>
  );
};

export default forwardRef(OpenedImagePlantas);

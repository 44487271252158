import React, { Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import Home from "../Home";
import Empreendimento from "../Empreendimento";

const history = createBrowserHistory();

export default function Routes() {
  return (
    <BrowserRouter history={history} basename="">
      <Switch>
        <Route path="/mesagafisa" exact component={Home} />
        <Route
          path="/empreendimento/sp/lancamento/:id"
          exact
          component={Empreendimento}
        />
        <Route
          path="/empreendimento/sp/emobras/:id"
          exact
          component={Empreendimento}
        />
        <Route
          path="/empreendimento/sp/prontoparamorar/:id"
          exact
          component={Empreendimento}
        />
        <Route
          path="/empreendimento/rj/lancamento/:id"
          exact
          component={Empreendimento}
        />
        <Route
          path="/empreendimento/rj/emobras/:id"
          exact
          component={Empreendimento}
        />
        <Route
          path="/empreendimento/rj/prontoparamorar/:id"
          exact
          component={Empreendimento}
        />
      </Switch>
    </BrowserRouter>
  );
}

import React, { useEffect, useState, Suspense, useRef } from "react";
import Carousel from "react-spring-3d-carousel";
import { v4 as uuidv4 } from "uuid";
import { useDrag, useGesture } from "react-use-gesture";

import { CarouselSliderStyle } from "./styled";

const CarouselSliderPlantas = ({
  setupCarouselPlantas,
  setSetupCarouselPlantas,
  imagesListPlantas,
}) => {
  const [slides, setSlides] = useState([]);
  const [dragged, _setDragged] = useState(false);
  const draggedRef = useRef(dragged);
  const setDragged = (data) => {
    draggedRef.current = data;
    _setDragged(data);
  };

  const bind = useGesture({
    onDrag: ({
      active,
      movement: [mx],
      direction: [xDir],
      distance,
      cancel,
    }) => {
      if (active && distance > window.innerWidth / 10) {
        let direction = Math.round(xDir);
        setDragged(true);
        cancel();
        if (direction === -1) {
          let newIndex = 0;
          if (
            setupCarouselPlantas.currentSlide <
            setupCarouselPlantas.slidesLength - 1
          ) {
            newIndex = setupCarouselPlantas.currentSlide + 1;
          }
          setSetupCarouselPlantas((values) => ({
            ...values,
            currentSlide: newIndex,
            goToSlide: newIndex,
          }));
        } else {
          let newIndex = setupCarouselPlantas.slidesLength;
          if (setupCarouselPlantas.currentSlide > 0) {
            newIndex = setupCarouselPlantas.currentSlide - 1;
          }
          setSetupCarouselPlantas((values) => ({
            ...values,
            currentSlide: newIndex,
            goToSlide: newIndex,
          }));
        }
      }
    },
  });

  const slidesTemp = imagesListPlantas.map((image, index) => {
    const idTrim = image.title.replace(/\s+/g, "") + index;
    return {
      key: uuidv4(),
      content: (
        <CarouselSliderStyle {...bind()}>
          <img
            draggable="false"
            src={image.url}
            id={idTrim}
            onClick={(e) => {
              if (!draggedRef.current) {
                setSetupCarouselPlantas((values) => ({
                  ...values,
                  currentSlide: index,
                  goToSlide: index,
                }));
              } else {
                setDragged(false);
              }
            }}
          />
        </CarouselSliderStyle>
      ),
    };
  });

  useEffect(() => {
    setSlides(slidesTemp);
  }, [imagesListPlantas]);

  // useEffect(() => {
  //   console.log(slides);
  // }, [slides]);

  if (setupCarouselPlantas) {
    return (
      <Suspense fallback={null}>
        <Carousel
          slides={slides}
          goToSlide={setupCarouselPlantas.goToSlide}
          offsetRadius={setupCarouselPlantas.offsetRadius}
          showNavigation={setupCarouselPlantas.showNavigation}
          animationConfig={setupCarouselPlantas.config}
        />
      </Suspense>
    );
  } else {
    return <></>;
  }
};

export default CarouselSliderPlantas;

import React, { useState } from "react";
import Routes from "./Components/Routes";
import SecaoProvider from "./context/SecaoContext";
import ConfigProvider from "./context/ConfigContext";
import GlobalStyle from "./shared/GlobalStyle";

function App() {
  return (
    <ConfigProvider>
      <SecaoProvider>
        <GlobalStyle />
        <Routes />
      </SecaoProvider>
    </ConfigProvider>
  );
}

export default App;

import React from "react";
import { useHistory } from "react-router-dom";
import { useConfig } from "../../context/ConfigContext";

import { Error, RefreshButton } from "./styled";

const ErrorScreen = () => {
  let history = useHistory();
  const { configApp, setConfigApp } = useConfig();

  return (
    <Error>
      <p>Ocorreu algum erro. Confira sua conexão com a internet.</p>
      <RefreshButton onClick={() => window.location.reload()}>
        Recarregar
      </RefreshButton>
      <RefreshButton
        onClick={() =>
          window.location.replace(configApp.baseURL + "mesaGafisa")
        }
      >
        Home
      </RefreshButton>
    </Error>
  );
};

export default ErrorScreen;

import React, { useEffect, useContext, useState, createContext } from "react";

const secaoModel = [];

const SecaoContext = createContext();

export default function SecaoProvider({ children }) {
  const [secao, setSecao] = useState(secaoModel);

  useEffect(() => {
    console.log(secao);
  }, [secao]);

  return (
    <SecaoContext.Provider value={{ secao, setSecao }}>
      {children}
    </SecaoContext.Provider>
  );
}

export function useSecao() {
  const context = useContext(SecaoContext);
  if (!context) throw new Error("useSecao must be used within a SecaoProvider");

  const { secao, setSecao } = context;

  return { secao, setSecao };
}

import styled from "styled-components";

export const DirectionsInfos = styled.div`
  pointer-events: none;
  user-select: none;
  position: absolute;
  z-index: 10000000000000;
  width: 327px;
  height: 74px;
  left: calc(25vw - 163px);
  bottom: 50px;

  background: #141414;
  border-radius: 1000px;
  opacity: 0;
  transition: all 1s ease-in-out 0s;

  &.in {
    opacity: 1;
    transition: all 1s ease-in-out 0.5s;
  }
`;

export const DirectionsInfosDistance = styled.div`
  position: absolute;
  display: block;
  width: 150px;
  height: 42px;
  right: 178px;
  top: 16px;

  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
  text-align: right;

  color: #e5e5e5;
`;

export const DirectionsInfosTime = styled.div`
  position: absolute;
  display: inline-block;
  width: 150px;
  height: 42px;
  left: 178px;
  top: 16px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
  text-align: left;

  color: #e5e5e5;
`;

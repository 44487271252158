import React, { useEffect, useState, Suspense, useRef } from "react";
import Carousel from "react-spring-3d-carousel";
import { v4 as uuidv4 } from "uuid";
import { useDrag, useGesture } from "react-use-gesture";

import { CarouselSliderStyle } from "./styled";

const CarouselSliderAreaComum = ({
  setupCarouselAreaComum,
  setSetupCarouselAreaComum,
  imagesListAreaComum,
}) => {
  const [slides, setSlides] = useState([]);
  const [dragged, _setDragged] = useState(false);
  const draggedRef = useRef(dragged);
  const setDragged = (data) => {
    draggedRef.current = data;
    _setDragged(data);
  };

  const bind = useGesture({
    onDrag: ({
      active,
      movement: [mx],
      direction: [xDir],
      distance,
      cancel,
    }) => {
      if (active && distance > window.innerWidth / 10) {
        let direction = Math.round(xDir);
        setDragged(true);
        cancel();
        // console.log(
        //   "direction",
        //   direction,
        //   "window.innerWidth",
        //   window.innerWidth
        // );
        if (direction === -1) {
          let newIndex = 0;
          // console.log(
          //   "currentSlide",
          //   setupCarouselAreaComum.currentSlide,
          //   "slidesLength",
          //   setupCarouselAreaComum.slidesLength
          // );
          if (
            setupCarouselAreaComum.currentSlide <
            setupCarouselAreaComum.slidesLength - 1
          ) {
            newIndex = setupCarouselAreaComum.currentSlide + 1;
            // console.log(newIndex);
          }
          setSetupCarouselAreaComum((values) => ({
            ...values,
            currentSlide: newIndex,
            goToSlide: newIndex,
          }));
        } else {
          let newIndex = setupCarouselAreaComum.slidesLength;
          if (setupCarouselAreaComum.currentSlide > 0) {
            newIndex = setupCarouselAreaComum.currentSlide - 1;
            // console.log(newIndex);
          }
          setSetupCarouselAreaComum((values) => ({
            ...values,
            currentSlide: newIndex,
            goToSlide: newIndex,
          }));
        }
      }
    },
  });

  const slidesTemp = imagesListAreaComum.map((image, index) => {
    const idTrim = image.title.replace(/\s+/g, "") + index;
    return {
      key: uuidv4(),
      content: (
        <CarouselSliderStyle {...bind()}>
          <img
            draggable="false"
            src={image.url}
            id={idTrim}
            onClick={(e) => {
              if (!draggedRef.current) {
                setSetupCarouselAreaComum((values) => ({
                  ...values,
                  currentSlide: index,
                  goToSlide: index,
                }));
              } else {
                setDragged(false);
              }
            }}
          />
        </CarouselSliderStyle>
      ),
    };
  });

  useEffect(() => {
    setSlides(slidesTemp);
  }, [imagesListAreaComum]);

  // useEffect(() => {
  //   console.log(slides);
  // }, [slides]);

  if (setupCarouselAreaComum) {
    return (
      <Suspense fallback={null}>
        <Carousel
          slides={slides}
          goToSlide={setupCarouselAreaComum.goToSlide}
          offsetRadius={setupCarouselAreaComum.offsetRadius}
          showNavigation={setupCarouselAreaComum.showNavigation}
          animationConfig={setupCarouselAreaComum.config}
        />
      </Suspense>
    );
  } else {
    return <></>;
  }
};

export default CarouselSliderAreaComum;

import styled, { keyframes, css } from "styled-components";
import linhasVerticais from "../images/linhas-verticais.png";
import simboloGafisa from "../images/simbolo-gafisa.svg";
import empreendimentoImage from "../images/MNO_03_Fachada_Detalhe_EF2_B_1.png";
import empreendimentoThumb from "../images/Vaga_REV04HR_1.png";
import empreendimentoThumbBig from "../images/Terraco_REV04HR_1.png";
import empreendimentoMapa from "../images/mapa.svg";
import mapaPath from "../images/path-map.svg";
import btFechar from "../images/bt-fechar.svg";
import btZoom from "../images/bt-zoom.svg";

export const lightAnimation = keyframes`
 0% { opacity:0; }
 50% { opacity:1; }
 100% { opacity:0; }
`;

export const EmpreendimentoContainer = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0px;
  top: 0px;
  background-image: linear-gradient(
      108.57deg,
      rgba(148, 158, 255, 0.55) -0.28%,
      rgba(148, 158, 255, 0) 32.03%,
      rgba(255, 26, 81, 0) 89.43%,
      rgba(255, 26, 81, 0.23) 110.8%,
      rgba(255, 26, 81, 0.23) 110.8%
    ),
    url(${linhasVerticais});
  background-color: #141414;
  background-size: cover;
`;

export const EmpreendimentoContainerBackground = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0px;
  top: 0px;
  background-image: linear-gradient(
      108.57deg,
      rgba(148, 158, 255, 0.55) -10.28%,
      rgba(148, 158, 255, 0) 9%,
      rgba(255, 26, 81, 0) 67.48%,
      rgba(255, 26, 81, 0.23) 99.8%,
      rgba(255, 26, 81, 0.23) 99.8%
    ),
    url(${linhasVerticais});
  background-color: #141414;
  background-size: cover;
  animation-name: ${lightAnimation};
  animation-duration: 10s;
  animation-iteration-count: infinite;
`;

export const LogoGafisa = styled.div`
  pointer-events: none;
  position: absolute;
  width: 32px;
  height: 27.62px;
  left: 3.85%;
  top: 22.5%;
  mix-blend-mode: normal;
  background-image: url(${simboloGafisa});
  opacity: 0;
  transition: all 1s ease-in-out 0s;

  &.in {
    pointer-events: all;
    opacity: 1;
    transition: all 1s ease-in-out 1s;
  }
`;

export const EmpreendimentoTitle = styled.div`
  pointer-events: none;
  user-select: none;
  position: absolute;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-end;
  width: 550px;
  height: 155px;
  left: 168px;
  top: 13px;

  color: #ffffff;
  opacity: 0;
  transition: all 0.4s ease-in-out 0s;

  .text {
    width: 100%;
    height: auto;
    flex-basis: auto;
    align-self: flex-end;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 50px;
    line-height: 50px;
    text-transform: uppercase;
  }

  &.in {
    opacity: 1;
    left: 175px;
    top: 53px;
    transition: all 0.4s ease-in-out 0.5s;
  }

  &.inMap {
    opacity: 1;
    left: 245px;
    top: 12px;
    transition: all 0.8s ease-in-out 0.2s;

    .text {
      width: 100%;
      height: auto;
      flex-basis: auto;
      align-self: flex-end;
      font-family: DM Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 40px;
      line-height: 40px;
      text-transform: uppercase;
    }
  }

  .sub {
    width: 100%;
    height: 20px;
    flexis-basis: auto;
    font-family: DM Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    text-transform: capitalize;
  }
`;

export const EmpreendimentoQr = styled.div`
  position: absolute;
  width: 61px;
  height: 61px;
  left: 147px;
  top: 226px;

  background: #ffffff;
  background-size: 85%;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 4px;

  opacity: 0;
  transition: all 0.7s ease-in-out 0s;

  &.in {
    opacity: 1;
    left: 177px;
    top: 226px;
    transition: all 0.7s ease-in-out 0.4s;
  }
`;

export const EmpreendimentoAddress = styled.div`
  pointer-events: none;
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: absolute;
  width: 550px;
  height: 62px;
  left: 230px;
  top: 227px;
  font-family: Space Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  opacity: 0;
  transition: all 0.7s ease-in-out 0s;

  &.in {
    opacity: 1;
    left: 260px;
    top: 227px;
    transition: all 0.7s ease-in-out 0.6s;
  }

  &.inMap {
    opacity: 1;
    left: 245px;
    top: 180px;
    transition: all 0.7s ease-in-out 0.4s;
  }
`;

export const EmpreendimentoAddressDetails = styled.div`
  pointer-events: none;
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: absolute;
  width: 184px;
  height: 62px;
  left: -20px;
  top: 20px;
  font-family: Space Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #c6c6c6;
  opacity: 0;
  transition: all 0.7s ease-in-out 0s;

  &.in {
    opacity: 1;
    left: 0px;
    top: 20px;
    transition: all 0.7s ease-in-out 0s;
  }
`;

export const EmpreendimentoUnits = styled.div`
  pointer-events: none;
  position: absolute;
  width: 86px;
  height: 104px;
  left: 437px;
  top: 195px;
  color: #ffffff;
  opacity: 0;
  transition: all 0.7s ease-in-out 0s;

  &.in {
    opacity: 1;
    transition: all 0.7s ease-in-out 0.4s;
  }
`;

export const UnitsNumber = styled.div`
  pointer-events: none;
  position: absolute;
  width: 140px;
  margin-left: -47px;
  font-family: Space Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 70px;
  line-height: 104px;
  text-align: right;
`;

export const UnitsText = styled.div`
  pointer-events: none;
  position: absolute;
  width: 166px;
  height: 64px;
  left: 100px;
  top: 20px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 32px;
  text-align: left;
  z-index: 10;
`;

export const UnitsCircle = styled.div`
  pointer-events: none;
  position: absolute;
  width: 102px;
  height: 102px;
  left: 200px;
  top: 105px;
`;

export const EmpreendimentoImagem = styled.div`
  pointer-events: none;
  position: absolute;
  width: 698px;
  height: 465px;
  left: -40px;
  top: 359px;
  background-image: url(${empreendimentoImage});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 0px 8px 8px 0px;
  z-index: 10;
  opacity: 0;
  transition: all 0.7s ease-in-out 0s;

  &.in {
    pointer-events: all;
    opacity: 1;
    left: 0px;
    top: 359px;
    transition: all 0.7s ease-in-out 0.6s;
  }
`;

export const EmpreendimentoPorcentagem = styled.div`
  pointer-events: none;
  position: absolute;
  width: 150px;
  left: 715px;
  top: 359px;
  opacity: 0;
  transition: all 0.7s ease-in-out 0s;

  &.in {
    opacity: 1;
    transition: all 0.7s ease-in-out 0.6s;
  }

  .number {
    position: absolute;
    width: 8px;
    height: 9px;
    left: 20px;
    top: 470px;

    font-family: Space Mono;
    font-style: normal;
    font-weight: normal;
    font-size: 6px;
    line-height: 9px;
    /* identical to box height */

    color: #ffffff;
  }

  .number2 {
    position: absolute;
    width: 8px;
    height: 9px;
    left: 0px;
    top: 470px;

    font-family: Space Mono;
    font-style: normal;
    font-weight: normal;
    font-size: 6px;
    line-height: 9px;
    /* identical to box height */

    color: #ffffff;
  }

  .text {
    position: absolute;
    width: 89px;
    height: 24px;
    left: 20px;
    top: 38px;
    font-family: Space Mono;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #c6c6c6;
  }
`;

export const EmpreendimentoPorcentagemBarra = styled.div`
  pointer-events: none;
  position: absolute;
  width: 9px;
  height: 465px;
  left: 20px;
  border-radius: 4px;
  background: linear-gradient(180deg, rgba(45, 45, 45, 0) 0%, #2d2d2d 100%);
  #empreendimento-porcentagem {
    position: absolute;
    width: 100%;
    height: 0%;
    bottom: 0px;
    border-radius: 4px;
    background: linear-gradient(
      180deg,
      #ff005d 0%,
      rgba(255, 79, 143, 0.47) 100%
    );
    transition: all 1s ease-in-out;
  }
`;

export const EmpreendimentoPorcentagemBarra2 = styled.div`
  pointer-events: none;
  position: absolute;
  width: 9px;
  height: 465px;
  left: 0px;
  border-radius: 4px;
  background: linear-gradient(180deg, rgba(45, 45, 45, 0) 0%, #2d2d2d 100%);
  #empreendimento-porcentagem2 {
    position: absolute;
    width: 100%;
    height: 0%;
    bottom: 0px;
    border-radius: 4px;
    background: linear-gradient(
      180deg,
      #ff005d 0%,
      rgba(255, 79, 143, 0.47) 100%
    );
    transition: all 1s ease-in-out;
  }
`;

export const EmpreendimentoDescription = styled.div`
  pointer-events: none;
  user-select: none;
  position: absolute;
  width: 348px;
  height: 90px;
  left: 176px;
  top: 871px;

  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  color: #8a8a8a;
  opacity: 0;
  transition: all 1s ease-in-out 0s;

  &.in {
    opacity: 1;
    transition: all 1s ease-in-out 0.8s;
  }
`;

export const EmpreendimentosPlantas = styled.div`
  pointer-events: none;
  flex: 1 1 auto;
  // background-color: pink;
  overflow-y: auto;
  flex-flow: column wrap;
  justify-content: flex-start !important;
  align-items: left;
  align-content: flex-start !important;
  position: absolute;
  width: 313px;
  height: 800px;
  min-height: 0vh;
  max-height: 800px;
  left: 878px;
  bottom: 30px;
  overflow-x: visible;
  overflow-y: auto;
  scroll-behavior: smooth;
  opacity: 0;
  transform: translateX(-50px);
  padding-top: 60px;
  padding-bottom: 60px;
  box-sizing: border-box;
  z-index: 0;
  transition: all 1s ease-in-out 0s;

  &::-webkit-scrollbar {
    display: none;
    transition: all 1s ease-in-out 0.8s;
  }

  &.in {
    pointer-events: all;
    opacity: 1;
    left: 878px;
    bottom: 30px;
    transform: translateX(0px);
    min-height: 0vh;
    max-height: 800px;
    transition: all 1s ease-in-out 0.8s;
  }
`;

export const EmpreendimentosPlantasDegrade = styled.div`
  pointer-events: none;
  position: absolute;
  width: 313px;
  height: 800px;
  left: 878px;
  bottom: 30px;
  opacity: 0;
  transform: translateX(-50px);
  transition: all 1s ease-in-out 0s;
  z-index: 100;
  background: linear-gradient(180deg, #141414 2%, rgba(20, 20, 20, 0) 20%),
    linear-gradient(180deg, rgba(20, 20, 20, 0) 80%, #141414 98%);

  &.in {
    opacity: 1;
    transform: translateX(0px);
    transition: all 1s ease-in-out 0.8s;
  }
`;

export const EmpreendimentosPlantasThumb = styled.div`
  width: 183px;
  height: 106px;
  align-self: flex-start;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 8px;
  overflow-x: visible;
  background-color: black;
  background-size: 135%;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;

  .title {
    pointer-events: none;
    position: absolute;
    width: 120px;
    height: 90px;
    left: 190px;
    display: flex;
    align-content: flex-end;
    align-items: flex-end;
    font-family: Space Mono;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
    color: #ffffff;
  }

  .text {
    pointer-events: none;
    position: absolute;
    width: 120px;
    height: 18px;
    left: 190px;
    transform: translateY(90px);
    font-family: Space Mono;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #c6c6c6;
  }
`;

export const EmpreendimentosThumbs = styled.div`
  pointer-events: none;
  position: absolute;
  width: 1000px;
  height: 250px;
  left: 1050px;
  top: 690px;
  // background-color: pink;
  border-radius: 0px;
  opacity: 0;
  transition: all 1s ease-in-out 0s;

  &.in {
    pointer-events: all;
    opacity: 1;
    left: 1100px;
    top: 690px;
    transition: all 1s ease-in-out 0.6s;
  }
`;

export const EmpreendimentosThumbsThumb = styled.div`
  position: absolute;
  width: 363px;
  height: 250px;
  left: 130px;
  top: 11px;
  background-size: 160%;
  background-position: center center;
  background-image: linear-gradient(
      269.85deg,
      #141414 3.73%,
      rgba(20, 20, 20, 0) 86.62%
    ),
    url(${empreendimentoThumb});
  border-radius: 8px;

  .text {
    position: absolute;
    width: 350px;
    height: 16px;
    left: 0px;
    top: 257px;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
  }
`;

export const EmpreendimentosThumbsThumbBig = styled.div`
  position: absolute;
  width: 389px;
  height: 272px;
  left: 350px;
  top: 0px;
  background-size: 160%;
  background-image: url(${empreendimentoThumbBig});
  background-position: center center;

  border-radius: 8px;
  .text {
    position: absolute;
    width: 350px;
    height: 16px;
    left: 0px;
    top: 292px;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
  }
`;

export const EmpreendimentosMap = styled.div`
  position: absolute;
  width: 767.8px;
  height: 509px;
  left: 1248px;
  top: 0px;
  background-repeat: no-repeat;
  background-image: url(${empreendimentoMapa});
  opacity: 0;
  transform: scale(0.9);
  transition: all 1s ease-in-out 0s;

  &.in {
    transform: scale(1);
    opacity: 1;
    transition: all 1s ease-in-out 0.5s;
  }
`;

export const EmpreendimentosMapEmbed = styled.div`
  pointer-events: all;
  cursor: pointer;
  position: absolute;
  width: 567.39px;
  height: 567.39px;
  right: 56px;
  top: -200px;
  border-radius: 50%;
  background-color: #edbbdc;
  transition: all 1s ease-in-out 0s;

  &.in {
    pointer-events: none;
    top: 0px;
    right: 100px;
    width: 50vw;
    height: 100vh;
    border-radius: 0%;
    transition: border-radius 1s ease-in-out 0.5s, width 1s ease-in-out 0.5s,
      height 1s ease-in-out 0.5s, top 0.5s ease-in-outs,
      right 0.5s ease-in-out 0s;
  }
`;

export const EmpreendimentosMapPath = styled.div`
  pointer-events: none;
  position: absolute;
  width: 461px;
  height: 216px;
  left: -21px;
  top: 90px;
  background-image: url(${mapaPath});
  opacity: 1;
  transition: all 1s ease-in-out;

  &.off {
    opacity: 0;
  }
`;

export const EmpreendimentosMapInterests = styled.div`
  position: absolute;
  width: 270px;
  height: 300px;
  left: -21px;
  top: 329px;
  padding-bottom: 50px;
  box-sizing: border-box;
  opacity: 1;
  overflow-x: hidden;
  overflow-y: scroll;
  transform: scale(1);
  transition: all 1s ease-in-out;

  &::-webkit-scrollbar {
    display: none;
  }

  &.off {
    pointer-events: none;
    opacity: 0;
  }

  &.active {
    width: 270px;
    height: 340px;
    left: -800px;
    top: 470px;
    transform: scale(2.5);
    opacity: 1;
  }
`;

export const EmpreendimentosMapInterestsItem = styled.div`
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-flow: row no-wrap;
  width: 270px;
  height: 18px;
  transition: all 1s ease-in-out 0s;

  &.active {
    height: 24px;
  }

  .meters {
    font-family: Space Mono;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #ffffff;
    transition: all 1s ease-in-out 0s;

    &.active {
      color: #c6c6c6;
    }
  }
  .text {
    padding-left: 10px;
    width: 178px;
    height: 18px;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #c6c6c6;
    transition: all 1s ease-in-out 0s;

    &.active {
      color: #ffffff;
    }
  }
`;

export const EmpreendimentosMapInterestsGradient = styled.div`
  pointer-events: none;
  position: fixed;
  z-index: 1;
  width: 48vw;
  height: 285px;
  left: 0px;
  bottom: 0px;
  background: linear-gradient(180deg, rgba(20, 20, 20, 0) 0%, #141414 100%);
  opacity: 0;
  transition: all 1s ease-in-out 0s;

  &.active {
    opacity: 1;
  }
`;

export const EmpreendimentosMapCloseBt = styled.div`
  pointer-events: none;
  position: absolute;
  z-index: 10000;
  width: 53px;
  height: 53px;
  left: 110px;
  bottom: 50px;
  background-image: url(${btFechar});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 0;
  transition: all 1s ease-in-out 0s;

  &.in {
    pointer-events: all;
    opacity: 1;
    transition: all 1s ease-in-out 1s;
  }
`;

const carouselContainerBase = css`
  pointer-events: none;
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.8);
  touchaction: "none";
  opacity: 0;
  transition: all 0.3s ease-in-out;

  &.in {
    pointer-events: all;
    opacity: 1;
  }

  .css-1fzpoyk {
    height: auto;
  }

  .css-1qzevvg {
    margin-top: -70px;
    z-index: 100;
  }
`;

export const CarouselContainerFachada = styled.div`
  ${carouselContainerBase}
`;

export const CarouselContainerAreaComum = styled.div`
  ${carouselContainerBase}
`;

export const CarouselContainerPerspectivas = styled.div`
  ${carouselContainerBase}
`;

export const CarouselContainerPlantas = styled.div`
  ${carouselContainerBase}
`;

export const CarouselCloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  z-index: 10000;
  width: 53px;
  height: 53px;
  left: 891px;
  bottom: 27px;
  background-image: url(${btFechar});
  border: 1px solid #aaaaaa;
  border-radius: 50%;
`;

export const CarouselZoomButton = styled.div`
  cursor: pointer;
  position: absolute;
  z-index: 10000;
  width: 53px;
  height: 53px;
  left: 977px;
  bottom: 27px;
  background-image: url(${btZoom});
  border: 1px solid #aaaaaa;
  border-radius: 50%;
`;

export const PlantaInferiorButton = styled.div`
  user-select: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 32px;

  position: absolute;
  width: 147px;
  height: 45px;
  left: 987px;
  bottom: 20px;

  background: #f1f1f1;
  border-radius: 8px;

  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */
  text-align: center;
  color: #000000;
  transition: all 0.3s ease-in-out;

  &.active {
    background: #ff0077;
    color: #ffffff;
  }
`;

export const PlantaSuperiorButton = styled(PlantaInferiorButton)`
  left: 1198px;
`;

const imageContainerBase = css`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 200;
  background-color: rgba(0, 0, 0, 1);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  touchaction: "none";
  opacity: 0;
  transition: all 0.3s ease-in-out;
  object-fit: contain;

  &.in {
    pointer-events: all;
    opacity: 1;
  }

  img {
    pointer-events: none;
    user-select: none;
    user-drag: none;
    height: 100%;
  }
`;

export const ImageContainerFachada = styled.div`
  ${imageContainerBase}
`;

export const ImageContainerAreaComum = styled.div`
  ${imageContainerBase}
`;

export const ImageContainerPerspectivas = styled.div`
  ${imageContainerBase}
`;

export const ImageContainerPlantas = styled.div`
  ${imageContainerBase}
`;

import styled from "styled-components";
import loadingGif from "../images/loading-ok.gif";

export const Error = styled.div`
  pointe-events: all;
  user-select: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  background-color: #232323;
  background-image: url(${loadingGif});
  background-repeat: no-repeat;
  background-size: 480px 480px;
  background-position: center center;
  z-index: 1000000;
  opacity: 1;
  transition: all 1s ease-in-out;
  &.off {
    pointer-events: none;
    opacity: 0;
  }

  p {
    color: #ffffff;
    font-family: Space Mono;
    font-style: normal;
    font-weight: normal;
    font-size: 8px;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: center;
  }
`;

export const RefreshButton = styled.button`
  width: 100px;
  height: 40px;
  margin-top: 20px;
`;

import styled from "styled-components";

export const CarouselSliderStyle = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 1589px;
  height: 872px;
  border-radius: 8px;
  overflow: hidden;
  touch-action: none;

  img {
    object-fit: cover !important;
    user-select: none;
    width: 100%;
  }
`;

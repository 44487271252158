import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { api } from "../../Services/Axios";
import LoadingScreen from "../LoadingScreen";
import ErrorScreen from "../ErrorScreen";
import {
  HomeContainer,
  HomeContainerBackground,
  HomeTitle,
  PizzaSP,
  PizzaRJ,
  PizzaTitleUF,
  HomeDescriptionSP,
  HomeDescriptionTitle,
  HomeDescriptionText,
  HomeDescriptionRJ,
  DashedLine,
  AroSP,
  AroRJ,
  ButtonLancamentoSP,
  ButtonEmObrasSP,
  ButtonProntoParaMorarSP,
  ButtonLancamentoRJ,
  ButtonEmObrasRJ,
  ButtonProntoParaMorarRJ,
  ListLancamentoSP,
  ListEmObrasSP,
  ListProntoParaMorarSP,
  ListLancamentoRJ,
  ListEmObrasRJ,
  ListProntoParaMorarRJ,
} from "./styled";

const Home = () => {
  const [homeClasses, setHomeClasses] = useState({
    homeTitle: "",
    homeDescriptionRJ: "",
    homeDescriptionSP: "",
    aroSP: "",
    aroRJ: "",
    pizzaSP: "",
    pizzaRJ: "",
    PizzaTitleUFSP: "",
    PizzaTitleUFRJ: "",
    dashedLine: "",
    circleSP: "",
    circleGlowSP: "",
    circleRJ: "",
    circleGlowRJ: "",
    buttonLancamentoSP: "",
    buttonEmObrasSP: "",
    buttonProntoParaMorarSP: "",
    buttonLancamentoRJ: "",
    buttonEmObrasRJ: "",
    buttonProntoParaMorarRJ: "",
    listLancamentoSP: "",
    listEmObrasSP: "",
    listProntoParaMorarSP: "",
    listLancamentoRJ: "",
    listEmObrasRJ: "",
    listProntoParaMorarRJ: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [errorScreen, setErrorScreen] = useState(false);
  const [
    empreendimentosLocale1Status1,
    setEmpreendimentosLocale1Status1,
  ] = useState([]);
  const [
    empreendimentosLocale1Status2,
    setEmpreendimentosLocale1Status2,
  ] = useState([]);
  const [
    empreendimentosLocale1Status3,
    setEmpreendimentosLocale1Status3,
  ] = useState([]);
  const [
    empreendimentosLocale2Status1,
    setEmpreendimentosLocale2Status1,
  ] = useState([]);
  const [
    empreendimentosLocale2Status2,
    setEmpreendimentosLocale2Status2,
  ] = useState([]);
  const [
    empreendimentosLocale2Status3,
    setEmpreendimentosLocale2Status3,
  ] = useState([]);

  useEffect(() => {
    api
      .get(`v1/empreendimentos?locale=1&status=1`)
      .then(function (response) {
        if (response.data.success === true) {
          // console.log(response);
          setEmpreendimentosLocale1Status1(response.data.data);
          setIsLoading(false);
          setErrorScreen(false);
        } else {
          // console.log(response);
          setIsLoading(false);
          setErrorScreen(true);
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
        setErrorScreen(true);
      });

    api
      .get(`v1/empreendimentos?locale=1&status=2`)
      .then(function (response) {
        if (response.data.success === true) {
          // console.log(response);
          setEmpreendimentosLocale1Status2(response.data.data);
          setIsLoading(false);
          setErrorScreen(false);
        } else {
          // console.log(response);
          setIsLoading(false);
          setErrorScreen(true);
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
        setErrorScreen(true);
      });

    api
      .get(`v1/empreendimentos?locale=1&status=3`)
      .then(function (response) {
        if (response.data.success === true) {
          // console.log(response);
          setEmpreendimentosLocale1Status3(response.data.data);
          setIsLoading(false);
          setErrorScreen(false);
        } else {
          // console.log(response);
          setIsLoading(false);
          setErrorScreen(true);
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
        setErrorScreen(true);
      });

    api
      .get(`v1/empreendimentos?locale=2&status=1`)
      .then(function (response) {
        if (response.data.success === true) {
          console.log(response);
          setEmpreendimentosLocale2Status1(response.data.data);
          setIsLoading(false);
          setErrorScreen(false);
        } else {
          // console.log(response);
          setIsLoading(false);
          setErrorScreen(true);
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
        setErrorScreen(true);
      });

    api
      .get(`v1/empreendimentos?locale=2&status=2`)
      .then(function (response) {
        if (response.data.success === true) {
          console.log(response);
          setEmpreendimentosLocale2Status2(response.data.data);
          setIsLoading(false);
          setErrorScreen(false);
        } else {
          // console.log(response);
          setIsLoading(false);
          setErrorScreen(true);
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
        setErrorScreen(true);
      });

    api
      .get(`v1/empreendimentos?locale=2&status=3`)
      .then(function (response) {
        if (response.data.success === true) {
          console.log(response);
          setEmpreendimentosLocale2Status3(response.data.data);
          setIsLoading(false);
          setErrorScreen(false);
        } else {
          // console.log(response);
          setIsLoading(false);
          setErrorScreen(true);
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
        setErrorScreen(true);
      });

    setHomeClasses((classes) => ({
      ...classes,
      homeTitle: "on",
      homeDescriptionRJ: "on",
      homeDescriptionSP: "on",
      aroSP: "",
      aroRJ: "",
      pizzaSP: "on",
      pizzaRJ: "on",
      PizzaTitleUFSP: "",
      PizzaTitleUFRJ: "",
      dashedLine: "on",
      circleSP: "",
      circleGlowSP: "",
      circleRJ: "",
      circleGlowRJ: "",
      buttonLancamentoSP: "",
      buttonEmObrasSP: "",
      buttonProntoParaMorarSP: "",
      buttonLancamentoRJ: "",
      buttonEmObrasRJ: "",
      buttonProntoParaMorarRJ: "",
      listLancamentoSP: "",
      listEmObrasSP: "",
      listProntoParaMorarSP: "",
      listLancamentoRJ: "",
      listEmObrasRJ: "",
      listProntoParaMorarRJ: "",
    }));
  }, []);

  const goHome = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setHomeClasses((classes) => ({
      ...classes,
      homeTitle: "on",
      homeDescriptionRJ: "on",
      homeDescriptionSP: "on",
      aroSP: "",
      aroRJ: "",
      pizzaSP: "on",
      pizzaRJ: "on",
      PizzaTitleUFSP: "",
      PizzaTitleUFRJ: "",
      dashedLine: "on",
      circleSP: "",
      circleGlowSP: "",
      circleRJ: "",
      circleGlowRJ: "",
      buttonLancamentoSP: "",
      buttonEmObrasSP: "",
      buttonProntoParaMorarSP: "",
      buttonLancamentoRJ: "",
      buttonEmObrasRJ: "",
      buttonProntoParaMorarRJ: "",
      listLancamentoSP: "",
      listEmObrasSP: "",
      listProntoParaMorarSP: "",
      listLancamentoRJ: "",
      listEmObrasRJ: "",
      listProntoParaMorarRJ: "",
    }));
  };

  const clickUF = (UF) => {
    if (UF === "SP") {
      setHomeClasses((classes) => ({
        ...classes,
        homeTitle: "off",
        homeDescriptionRJ: "off",
        homeDescriptionSP: "off",
        aroSP: "in",
        aroRJ: "",
        pizzaSP: "in",
        pizzaRJ: "half",
        PizzaTitleUFSP: "",
        PizzaTitleUFRJ: "half",
        dashedLine: "off",
        circleSP: "in",
        circleGlowSP: "in",
        circleRJ: "half",
        circleGlowRJ: "half",
        buttonLancamentoSP: "in",
        buttonEmObrasSP: "in",
        buttonProntoParaMorarSP: "in",
        buttonLancamentoRJ: "",
        buttonEmObrasRJ: "",
        buttonProntoParaMorarRJ: "",
        listLancamentoSP: "",
        listEmObrasSP: "",
        listProntoParaMorarSP: "",
        listLancamentoRJ: "",
        listEmObrasRJ: "",
        listProntoParaMorarRJ: "",
      }));
    } else if (UF === "RJ") {
      setHomeClasses((classes) => ({
        ...classes,
        homeTitle: "off",
        homeDescriptionRJ: "off",
        homeDescriptionSP: "off",
        aroSP: "",
        aroRJ: "in",
        pizzaSP: "half",
        pizzaRJ: "in",
        PizzaTitleUFSP: "half",
        PizzaTitleUFRJ: "",
        dashedLine: "off",
        circleSP: "half",
        circleGlowSP: "half",
        circleRJ: "in",
        circleGlowRJ: "in",
        buttonLancamentoSP: "",
        buttonEmObrasSP: "",
        buttonProntoParaMorarSP: "",
        buttonLancamentoRJ: "in",
        buttonEmObrasRJ: "in",
        buttonProntoParaMorarRJ: "in",
        listLancamentoSP: "",
        listEmObrasSP: "",
        listProntoParaMorarSP: "",
        listLancamentoRJ: "",
        listEmObrasRJ: "",
        listProntoParaMorarRJ: "",
      }));
    }
  };

  const clickEmpreendimentosSP = (andamento) => {
    if (andamento === "lancamento") {
      setHomeClasses((classes) => ({
        ...classes,
        buttonLancamentoSP: "in active",
        buttonEmObrasSP: "in deactive",
        buttonProntoParaMorarSP: "in deactive",
        pizzaRJ: "off",
        listLancamentoSP: "in",
        listEmObrasSP: "",
        listProntoParaMorarSP: "",
        listLancamentoRJ: "",
        listEmObrasRJ: "",
        listProntoParaMorarRJ: "",
      }));
    } else if (andamento === "emObras") {
      setHomeClasses((classes) => ({
        ...classes,
        buttonLancamentoSP: "in deactive",
        buttonEmObrasSP: "in active",
        buttonProntoParaMorarSP: "in deactive",
        pizzaRJ: "off",
        listLancamentoSP: "",
        listEmObrasSP: "in",
        listProntoParaMorarSP: "",
        listLancamentoRJ: "",
        listEmObrasRJ: "",
        listProntoParaMorarRJ: "",
      }));
    } else if (andamento === "prontoParaMorar") {
      setHomeClasses((classes) => ({
        ...classes,
        buttonLancamentoSP: "in deactive",
        buttonEmObrasSP: "in deactive",
        buttonProntoParaMorarSP: "in active",
        pizzaRJ: "off",
        listLancamentoSP: "",
        listEmObrasSP: "",
        listProntoParaMorarSP: "in",
        listLancamentoRJ: "",
        listEmObrasRJ: "",
        listProntoParaMorarRJ: "",
      }));
    }
  };

  const clickEmpreendimentosRJ = (andamento) => {
    if (andamento === "lancamento") {
      setHomeClasses((classes) => ({
        ...classes,
        buttonLancamentoRJ: "in active",
        buttonEmObrasRJ: "in deactive",
        buttonProntoParaMorarRJ: "in deactive",
        pizzaSP: "off",
        listLancamentoSP: "",
        listEmObrasSP: "",
        listProntoParaMorarSP: "",
        listLancamentoRJ: "in",
        listEmObrasRJ: "",
        listProntoParaMorarRJ: "",
      }));
    } else if (andamento === "emObras") {
      setHomeClasses((classes) => ({
        ...classes,
        buttonLancamentoRJ: "in deactive",
        buttonEmObrasRJ: "in active",
        buttonProntoParaMorarRJ: "in deactive",
        pizzaSP: "off",
        listLancamentoSP: "",
        listEmObrasSP: "",
        listProntoParaMorarSP: "",
        listLancamentoRJ: "",
        listEmObrasRJ: "in",
        listProntoParaMorarRJ: "",
      }));
    } else if (andamento === "prontoParaMorar") {
      setHomeClasses((classes) => ({
        ...classes,
        buttonLancamentoRJ: "in deactive",
        buttonEmObrasRJ: "in deactive",
        buttonProntoParaMorarRJ: "in active",
        pizzaSP: "off",
        listLancamentoSP: "",
        listEmObrasSP: "",
        listProntoParaMorarSP: "",
        listLancamentoRJ: "",
        listEmObrasRJ: "",
        listProntoParaMorarRJ: "in",
      }));
    }
  };

  return (
    <>
      {isLoading ? <LoadingScreen /> : null}
      {errorScreen ? <ErrorScreen /> : null}
      <HomeContainer onClick={goHome}>
        <HomeContainerBackground />
        <HomeTitle className={homeClasses.homeTitle}>
          Onde você quer morar?
        </HomeTitle>
        <AroSP className={homeClasses.aroSP} />
        <AroRJ className={homeClasses.aroRJ} />
        {empreendimentosLocale1Status1.length !== 0 && (
          <ButtonLancamentoSP
            className={homeClasses.buttonLancamentoSP}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              clickEmpreendimentosSP("lancamento");
            }}
          >
            {/* <div className="number">09</div> */}
            <div className="text">lançamento</div>
          </ButtonLancamentoSP>
        )}
        {empreendimentosLocale1Status2.length !== 0 && (
          <ButtonEmObrasSP
            className={homeClasses.buttonEmObrasSP}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              clickEmpreendimentosSP("emObras");
            }}
          >
            {/* <div className="number">09</div> */}
            <div className="text">Em Obras</div>
          </ButtonEmObrasSP>
        )}
        {empreendimentosLocale1Status3.length !== 0 && (
          <ButtonProntoParaMorarSP
            className={homeClasses.buttonProntoParaMorarSP}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              clickEmpreendimentosSP("prontoParaMorar");
            }}
          >
            {/* <div className="number">09</div> */}
            <div className="text">Pronto para morar</div>
          </ButtonProntoParaMorarSP>
        )}
        {empreendimentosLocale2Status1.length !== 0 && (
          <ButtonLancamentoRJ
            className={homeClasses.buttonLancamentoRJ}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              clickEmpreendimentosRJ("lancamento");
            }}
          >
            {/* <div className="number">09</div> */}
            <div className="text">lançamento</div>
          </ButtonLancamentoRJ>
        )}
        {empreendimentosLocale2Status2.length !== 0 && (
          <ButtonEmObrasRJ
            className={homeClasses.buttonEmObrasRJ}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              clickEmpreendimentosRJ("emObras");
            }}
          >
            {/* <div className="number">09</div> */}
            <div className="text">Em Obras</div>
          </ButtonEmObrasRJ>
        )}
        {empreendimentosLocale2Status3.length !== 0 && (
          <ButtonProntoParaMorarRJ
            className={homeClasses.buttonProntoParaMorarRJ}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              clickEmpreendimentosRJ("prontoParaMorar");
            }}
          >
            {/* <div className="number">09</div> */}
            <div className="text">Pronto para morar</div>
          </ButtonProntoParaMorarRJ>
        )}
        <PizzaSP
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            clickUF("SP");
          }}
          className={homeClasses.pizzaSP}
        >
          <div className={`circle ${homeClasses.circleSP}`}>
            <div className={`circleGlow ${homeClasses.circleGlowSP}`}></div>
          </div>
          <PizzaTitleUF className={homeClasses.PizzaTitleUFSP}>SP</PizzaTitleUF>
        </PizzaSP>
        <PizzaRJ
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            clickUF("RJ");
          }}
          className={homeClasses.pizzaRJ}
        >
          <div className={`circle ${homeClasses.circleRJ}`}>
            <div className={`circleGlow ${homeClasses.circleGlowRJ}`}></div>
          </div>
          <PizzaTitleUF className={homeClasses.PizzaTitleUFRJ}>RJ</PizzaTitleUF>
        </PizzaRJ>
        <HomeDescriptionSP className={homeClasses.homeDescriptionSP}>
          <HomeDescriptionTitle>São Paulo</HomeDescriptionTitle>
          <HomeDescriptionText>
            Elevação 760 m<br />
            18ºC max / 12ºC min
            <br />
            12.330 MI Habitantes
          </HomeDescriptionText>
        </HomeDescriptionSP>
        <HomeDescriptionRJ className={homeClasses.homeDescriptionRJ}>
          <HomeDescriptionTitle>Rio de Janeiro</HomeDescriptionTitle>
          <HomeDescriptionText>
            Elevação 2.13 m<br />
            24ºC max / 21ºC min
            <br />
            6.748 MI Habitantes
          </HomeDescriptionText>
        </HomeDescriptionRJ>
        <ListLancamentoSP className={homeClasses.listLancamentoSP}>
          {empreendimentosLocale1Status1.map((empreendimento, index) => (
            <li key={index} className={homeClasses.listLancamentoSP}>
              <Link to={`/empreendimento/sp/lancamento/${empreendimento.id}`}>
                <div className="bullet">{index + 1}</div>
                {empreendimento.name}
              </Link>
            </li>
          ))}
        </ListLancamentoSP>
        <ListEmObrasSP className={homeClasses.listEmObrasSP}>
          {empreendimentosLocale1Status2.map((empreendimento, index) => (
            <li key={index} className={homeClasses.listEmObrasSP}>
              <Link to={`/empreendimento/sp/emobras/${empreendimento.id}`}>
                <div className="bullet">{index + 1}</div>
                {empreendimento.name}
              </Link>
            </li>
          ))}
        </ListEmObrasSP>
        <ListProntoParaMorarSP className={homeClasses.listProntoParaMorarSP}>
          {empreendimentosLocale1Status3.map((empreendimento, index) => (
            <li key={index} className={homeClasses.listProntoParaMorarSP}>
              <Link
                to={`/empreendimento/sp/prontoparamorar/${empreendimento.id}`}
              >
                <div className="bullet">{index + 1}</div>
                {empreendimento.name}
              </Link>
            </li>
          ))}
        </ListProntoParaMorarSP>
        <ListLancamentoRJ className={homeClasses.listLancamentoRJ}>
          {empreendimentosLocale2Status1.map((empreendimento, index) => (
            <li key={index} className={homeClasses.listLancamentoRJ}>
              <Link to={`/empreendimento/rj/lancamento/${empreendimento.id}`}>
                <div className="bullet">{index + 1}</div>
                {empreendimento.name}
              </Link>
            </li>
          ))}
        </ListLancamentoRJ>
        <ListEmObrasRJ className={homeClasses.listEmObrasRJ}>
          {empreendimentosLocale2Status2.map((empreendimento, index) => (
            <li key={index} className={homeClasses.listEmObrasRJ}>
              <Link to={`/empreendimento/rj/emobras/${empreendimento.id}`}>
                <div className="bullet">{index + 1}</div>
                {empreendimento.name}
              </Link>
            </li>
          ))}
        </ListEmObrasRJ>
        <ListProntoParaMorarRJ className={homeClasses.listProntoParaMorarRJ}>
          {empreendimentosLocale2Status3.map((empreendimento, index) => (
            <li key={index} className={homeClasses.listProntoParaMorarRJ}>
              <Link
                to={`/empreendimento/rj/prontoparamorar/${empreendimento.id}`}
              >
                <div className="bullet">{index + 1}</div>
                {empreendimento.name}
              </Link>
            </li>
          ))}
        </ListProntoParaMorarRJ>
        <DashedLine className={homeClasses.dashedLine} />
      </HomeContainer>
    </>
  );
};

export default Home;
